import {BreadCrumbProps, Crumb} from "src/view/commons/breadCrumb/breadcrumb_bar";

export const HomeCrumb: Crumb = {
    crumbLabel: "Home",
    crumbPath: "/"
}

export const AdminHomeCrumb: Crumb = {
    crumbLabel: "Home",
    crumbPath: "/admin"
}

export const JobTemplateCrumbs = (matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = [];
    crumbs.push(HomeCrumb);
    crumbs.push({
        crumbLabel: "Job Templates",
        crumbPath: "/jobTemplate"
    })
    switch (path) {
        case "/jobTemplate":
            break;
        case "/jobTemplate/create": {
            crumbs.push({
                crumbLabel: "Create",
                crumbPath: "/create"
            })
            break;
        }
        case "/jobTemplate/:jobTemplateId/view": {
            crumbs.push({
                crumbLabel: "View",
                crumbPath: `/view/${matchedParams.jobTemplateId}`
            })
            break;
        }
        case "/jobTemplate/:jobTemplateId/update": {
            crumbs.push({
                crumbLabel: "Update",
                crumbPath: `/update/${matchedParams.jobTemplateId}`
            })
            break;
        }
    }
    return crumbs;
}

export const DatasetCrumbs = (matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = [];
    if (path == "/datasets") {
        crumbs.push(HomeCrumb);
        crumbs.push({
            crumbLabel: "Datasets",
            crumbPath: "/datasets"
        })
    }

    return crumbs;
}

export const CodeRepositoriesCrumbs = (matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = [];
    crumbs.push(HomeCrumb);
    if (path.includes("/codeRepositories")) {
        crumbs.push({
            crumbLabel: "Code Repositories",
            crumbPath: "/codeRepositories"
        })
    }

    return crumbs;
}

export const ConfigPanelCrumbs = (matchedParams: any, path: string): Crumb[] | null => {

    let crumbs: Crumb[] = []

    crumbs.push(HomeCrumb);

    let teamId = matchedParams.teamId;
    if (!teamId) {
        return null;
    }
    //TODO: Change this with Routes Map approach
    let isConfigView = path == "/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/view"
    let isConfigEdit = path == "/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/edit"
    let isConfigMerge = path == "/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/merge"

    crumbs.push({
        crumbLabel: "Configuration Panel",
        crumbPath: `/configPanel/${teamId}/namespaces`
    });

    let namespaceId = matchedParams.namespaceId;
    let configId = matchedParams.configId;

    !namespaceId && crumbs.push({
        crumbLabel: "Namespaces",
        crumbPath: `/configPanel/${teamId}/namespaces`
    });


    namespaceId && crumbs.push({
        crumbLabel: `Namespace: ${namespaceId}`,
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs`
    });

    namespaceId && !configId && crumbs.push({
        crumbLabel: "Configs",
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs`
    });

    configId && crumbs.push({
        crumbLabel: `Config: ${configId}`,
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs/${configId}/view`
    });

    configId && isConfigView && crumbs.push({
        crumbLabel: `View`,
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs/${configId}/view`
    });

    configId && isConfigEdit && crumbs.push({
        crumbLabel: `Edit`,
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs/${configId}/edit`
    });

    configId && isConfigMerge && crumbs.push({
        crumbLabel: `Merge`,
        crumbPath: `/configPanel/${teamId}/namespaces/${namespaceId}/configs/${configId}/merge`
    });

    return crumbs;
}

// TODO - change the routing to format - Routes.get(path) == "createNamespaceRoute"

export const FeatureGenerationCrumbs = (matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = []

    crumbs.push(HomeCrumb);

    let teamId = matchedParams.teamId;
    let namespaceId = matchedParams.namespaceId;

    if (!teamId) return null;

    crumbs.push({
        crumbLabel: "Feature Generation",
        crumbPath: `/featureGeneration/${teamId}/namespaces`
    });

    !namespaceId && crumbs.push({
        crumbLabel: "Namespaces",
        crumbPath: `/featureGeneration/${teamId}/namespaces`
    });

    !namespaceId && path.includes('new') && crumbs.push({
        crumbLabel: "Create Namespace",
        crumbPath: `/featureGeneration/${teamId}/namespaces/new`
    });

    namespaceId && crumbs.push({
        crumbLabel: `Namespace: ${namespaceId}`,
        crumbPath: `/featureGeneration/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && path.includes('view') && crumbs.push({
        crumbLabel: `View`,
        crumbPath: `/featureGeneration/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && path.includes('jobs') && crumbs.push({
        crumbLabel: "Jobs",
        crumbPath: `/featureGeneration/${teamId}/namespaces/${namespaceId}/jobs`
    });

    namespaceId && path.includes('jobs') && path.includes('new') && crumbs.push({
        crumbLabel: "Create Job",
        crumbPath: `/featureGeneration/${teamId}/namespaces/${namespaceId}/jobs`
    });

    return crumbs;
}

export const ModelTrainingCrumbs = (matchedParams: any, path: string): Crumb[] | null => {

    let crumbs: Crumb[] = []

    crumbs.push(HomeCrumb);

    let teamId = matchedParams.teamId;
    if (!teamId) {
        return null;
    }

    let namespaceId = matchedParams.namespaceId;
    let runwayId = matchedParams.runwayId;
    let version = matchedParams.version
    let experimentName = matchedParams.experimentName;

    crumbs.push({
        crumbLabel: "Model Training",
        crumbPath: `/modelTraining/${teamId}/namespaces`
    });

    !namespaceId && crumbs.push({
        crumbLabel: "Namespaces",
        crumbPath: `/modelTraining/${teamId}/namespaces`
    });

    !namespaceId && path.includes("create") && crumbs.push({
        crumbLabel: "Create Namespace",
        crumbPath: `/modelTraining/${teamId}/namespaces/create`
    });

    namespaceId && crumbs.push({
        crumbLabel: `Namespace: ${namespaceId}`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && path.includes("view") && crumbs.push({
        crumbLabel: `View`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && path.includes("runways") && crumbs.push({
        crumbLabel: `Runways`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways`
    });

    namespaceId && runwayId && version && path.includes("runways") && path.includes("update") && crumbs.push({
        crumbLabel: `Update Runway`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/${version}/update`
    });

    namespaceId && runwayId && path.includes("runways") && path.includes("view") && crumbs.push({
        crumbLabel: `View Runway`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/view`
    });

    namespaceId && runwayId && path.includes("runways") && path.includes("executions") && crumbs.push({
        crumbLabel: `Runway Executions`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/executions`
    });

    namespaceId && !runwayId && path.includes("runways") && path.includes("create") && crumbs.push({
        crumbLabel: `Create Runway`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/create`
    });

    namespaceId && runwayId && !path.includes("executions") && crumbs.push({
        crumbLabel: `Runway: ${runwayId}`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/experiments`
    });

    namespaceId && !version && !experimentName && !path.includes("create") && path.includes("experiments") && runwayId && crumbs.push({
        crumbLabel: `Experiments`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/experiments`
    });

    namespaceId && runwayId && experimentName && path.includes("edit") && crumbs.push({
        crumbLabel: `Edit: ${experimentName}`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/${experimentName}/edit`
    });

    namespaceId && path.includes("create") && runwayId && crumbs.push({
        crumbLabel: `Create Experiment`,
        crumbPath: `/modelTraining/${teamId}/namespaces/${namespaceId}/runways/${runwayId}/experiments/create`
    });

    return crumbs;
}

export const NotebooksCrumb = (entityNames: BreadCrumbProps, matchedParams: any, path: string): Crumb[] | null => {

    let crumbs: Crumb[] = [];
    let teamId = matchedParams.teamId;
    if (!teamId) {
        return null;
    }
    let namespaceId = matchedParams.namespaceId;
    let dataStudioOnboardingId = matchedParams.onboardingId;
    let dataStudioAddonId = matchedParams.addonId;
    let namespaceName = entityNames.namespaceName;
    let runwayId = matchedParams.runwayId;
    let experimentName = matchedParams.experimentName;

    let dataStudioOnboardingName = entityNames.dataStudioOnboardingName;
    let dataStudioManagedEndpointName = entityNames.dataStudioManagedEndpointName;

    crumbs.push(HomeCrumb);

    crumbs.push({
        crumbLabel: "Notebooks",
        crumbPath: `/notebooks/${teamId}/namespaces`
    });

    !namespaceId && path.includes("create") && crumbs.push({
        crumbLabel: "Create Namespace",
        crumbPath: `/notebooks/${teamId}/namespaces/create`
    });

    namespaceId && crumbs.push({
        crumbLabel: `Namespace: ${namespaceName}`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}`
    });

    namespaceId && !runwayId && path.includes("view") && crumbs.push({
        crumbLabel: `Details`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && !runwayId && path.includes("edit") && crumbs.push({
        crumbLabel: `Edit`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/edit`
    });

    namespaceId && path.includes("experiments") && runwayId && crumbs.push({
        crumbLabel: `Experiments`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/${runwayId}/experiments`
    });

    namespaceId && runwayId && experimentName && path.includes("edit") && crumbs.push({
        crumbLabel: `Edit: ${experimentName}`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/${runwayId}/${experimentName}/edit`
    });

    namespaceId && path.includes("create") && runwayId && crumbs.push({
        crumbLabel: `Create Experiment`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/${runwayId}/experiments/create`
    });

    return crumbs;
}


export const RunwaysCrumbs = (entityNames: BreadCrumbProps, matchedParams: any, path: string): Crumb[] | null => {

    let crumbs: Crumb[] = []

    let teamId = matchedParams.teamId;
    if (!teamId) {
        return null;
    }

    let namespaceId = matchedParams.namespaceId;
    let runwayId = matchedParams.runwayId;
    let jobId = matchedParams.jobId;
    let jobName = entityNames.jobName;
    let runwayName = entityNames.runwayName;
    let namespaceName = entityNames.namespaceName
    let version = matchedParams.version;

    crumbs.push(HomeCrumb);

    crumbs.push({
        crumbLabel: "Runways",
        crumbPath: `/runways/${teamId}/namespaces`
    });

    !namespaceId && path.includes("create") && crumbs.push({
        crumbLabel: "Create Namespace",
        crumbPath: `/runways/${teamId}/namespaces/create`
    });

    namespaceId && crumbs.push({
        crumbLabel: `Namespace: ${namespaceName}`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}?domain=PROD`
    });

    namespaceId && !runwayId && path.includes("view") && crumbs.push({
        crumbLabel: `Details`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/view`
    });

    namespaceId && !runwayId && path.includes("edit") && crumbs.push({
        crumbLabel: `Edit`,
        crumbPath: `/notebooks/${teamId}/namespaces/${namespaceId}/edit`
    });

    namespaceId && !runwayId && path.includes("runways") && path.includes("create") && crumbs.push({
        crumbLabel: `Create Runway`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/create`
    });

    namespaceId && runwayId && crumbs.push({
        crumbLabel: `Runway: ${runwayName}`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/${runwayId}/view`
    });

    namespaceId && runwayId && path.includes("runways") && path.includes("view") && crumbs.push({
        crumbLabel: `View Runway`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/${runwayId}/view`
    });

    namespaceId && runwayId && version && path.includes("runways") && path.includes("update") && crumbs.push({
        crumbLabel: `Update Runway`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/${runwayId}/${version}/update`
    });

    namespaceId && runwayId && !jobId && path.includes("runways") && path.includes("executions") && crumbs.push({
        crumbLabel: `Runway Executions`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/${runwayId}/executions`
    });

    namespaceId && runwayId && jobId && path.includes("jobs") && path.includes("executions") && crumbs.push({
        crumbLabel: `Job Executions : ${jobName}`,
        crumbPath: `/runways/${teamId}/namespaces/${namespaceId}/${runwayId}/jobs/${jobId}/executions`
    });

    return crumbs;
}

export const DataStudioAdminCrumbs = (entityNames: BreadCrumbProps, matchedParams: any, path: string): Crumb[] | null => {

    let crumbs: Crumb[] = [];
    // let teamId = matchedParams.teamId;
    // if (!teamId) {
    //     return null;
    // }
    let dataStudioOnboardingId = matchedParams.onboardingId;
    let dataStudioAddonId = matchedParams.addonId;

    let dataStudioOnboardingName = entityNames.dataStudioOnboardingName;
    let dataStudioManagedEndpointName = entityNames.dataStudioManagedEndpointName;
    let dataStudioVpcEndpointName = entityNames.dataStudioVpcEndpointName;

    crumbs.push(AdminHomeCrumb);

    // crumbs.push({
    //     crumbLabel: "DataStudio",
    //     crumbPath: `/admin/dataStudio/onboardings`
    // });

    path.includes("dataStudio/onboardings") && crumbs.push({
        crumbLabel: "Onboardings",
        crumbPath: `/admin/dataStudio/onboardings`
    });

    dataStudioOnboardingId && crumbs.push({
        crumbLabel: `Onboarding: ${dataStudioOnboardingName}`,
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/view`
    });

    // dataStudioOnboardingId && !dataStudioAddonId && path.includes("view") && crumbs.push({
    //     crumbLabel: `View`,
    //     crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/view`
    // });

    dataStudioOnboardingId && path.includes("onboardings") && path.includes("addons") && crumbs.push({
        crumbLabel: "Addons",
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons`
    });

    dataStudioOnboardingId && path.includes("managed-endpoint/create") && path.endsWith('create') && crumbs.push({
        crumbLabel: "Create Managed-Endpoint",
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/managed-endpoint/create`
    });

    dataStudioOnboardingId && path.includes("managed-endpoint/create-detailed") && crumbs.push({
        crumbLabel: "Create Managed-Endpoint",
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/managed-endpoint/create-detailed`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("managed-endpoint/view") && crumbs.push({
        crumbLabel: `View Managed-Endpoint : ${dataStudioManagedEndpointName}`,
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/managed-endpoint/view`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("managed-endpoint/clone") && crumbs.push({
        crumbLabel: `Clone Managed-Endpoint : ${dataStudioManagedEndpointName}`,
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/managed-endpoint/clone`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("vpc-endpoint/view") && crumbs.push({
        crumbLabel: `View Vpc-Endpoint : ${dataStudioVpcEndpointName}`,
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/vpc-endpoint/view`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("vpc-endpoint/create") && path.endsWith('create') && crumbs.push({
        crumbLabel: `Create Vpc-Endpoint : ${dataStudioVpcEndpointName}`,
        crumbPath: `/admin/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/vpc-endpoint/create`
    });

    return crumbs;
}

export const TeamCrumbs = (entityNames: BreadCrumbProps, matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = [];

    let teamId = matchedParams.teamId
    let teamName = entityNames.teamName
    crumbs.push(HomeCrumb);

    crumbs.push({
        crumbLabel: "Teams",
        crumbPath: `/`
    });

    path.includes("create") && crumbs.push({
        crumbLabel: "Create",
        crumbPath: `/team/create`
    });

    teamId && crumbs.push({
        crumbLabel: `Team : ${teamName}`,
        crumbPath: `/team/${teamId}/view`
    })

    teamId && path.includes("view") && crumbs.push({
        crumbLabel: "View",
        crumbPath: `/team/${teamId}/view`
    });

    return crumbs;
}

export const DataStudioCrumbs = (entityNames: BreadCrumbProps, matchedParams: any, path: string): Crumb[] | null => {
    let crumbs: Crumb[] = [];

    // let teamId = matchedParams.teamId;
    // if (!teamId) {
    //     return null;
    // }
    // let namespaceId = matchedParams.namespaceId;
    let dataStudioOnboardingId = matchedParams.onboardingId;
    let dataStudioAddonId = matchedParams.addonId;

    let dataStudioOnboardingName = entityNames.dataStudioOnboardingName;
    let dataStudioManagedEndpointName = entityNames.dataStudioManagedEndpointName;
    let dataStudioVpcEndpointName = entityNames.dataStudioVpcEndpointName;

    // path.includes("dataStudio") && crumbs.push({
    //     crumbLabel: `Data Studio`,
    //     crumbPath: `/dataStudio/onboardings`
    // });

    path.includes("dataStudio") && path.includes("onboardings") && crumbs.push({
        crumbLabel: `Onboardings`,
        crumbPath: `/dataStudio/onboardings`
    });

    !dataStudioOnboardingId && !dataStudioAddonId && path.includes("create") && crumbs.push({
        crumbLabel: `Create`,
        crumbPath: `/dataStudio/onboardings/create`
    });

    dataStudioOnboardingId && crumbs.push({
        crumbLabel: `Onboarding: ${dataStudioOnboardingName}`,
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/view`
    });

    // dataStudioOnboardingId && !dataStudioAddonId && path.includes("view") && crumbs.push({
    //     crumbLabel: `View`,
    //     crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/view`
    // });

    dataStudioOnboardingId && path.includes("addons") && crumbs.push({
        crumbLabel: `Addons`,
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("managed-endpoint/view") && crumbs.push({
        crumbLabel: `View Managed-Endpoint : ${dataStudioManagedEndpointName}`,
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/managed-endpoint/view`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("vpc-endpoint/view") && crumbs.push({
        crumbLabel: `View Vpc-Endpoint : ${dataStudioVpcEndpointName}`,
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/view-endpoint/view`
    });

    dataStudioOnboardingId && path.includes("managed-endpoint/create") && path.endsWith('create') && crumbs.push({
        crumbLabel: "Create Managed-Endpoint",
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons/managed-endpoint/create`
    });

    dataStudioOnboardingId && path.includes("managed-endpoint/create-detailed") && crumbs.push({
        crumbLabel: "Create Managed-Endpoint",
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons/managed-endpoint/create-detailed`
    });

    dataStudioOnboardingId && dataStudioAddonId && path.includes("vpc-endpoint/view") && path.endsWith('create') && crumbs.push({
        crumbLabel: `Create Vpc-Endpoint : ${dataStudioVpcEndpointName}`,
        crumbPath: `/dataStudio/onboardings/${dataStudioOnboardingId}/addons/${dataStudioAddonId}/view-endpoint/create`
    });

    return crumbs;
}
