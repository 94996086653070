import { useDispatch, useSelector } from "react-redux";
import { dataStudioOnboardingSelector } from "src/control/selectors/notebooks/data_studio_selectors";
import React, { useEffect, useRef, useState } from "react";
import { dataStudioInitialState, DataStudioOnboardingStatus, } from "src/model/notebooks/data_studio_models";
import { useParams } from "react-router-dom";
import {
    createDataStudioTeamAndOnboarding,
    getDataStudioOnboarding,
    getDataStudioOnboardingAccount
} from "src/control/actions/notebooks/data_studio_actions";
import { OperationType, Status, TEN_MINUTE_POLLING_TIME_INTERVAL } from "src/constant";
import { KatAlert, KatBadge, KatButton, KatCard, KatFlashbar, KatSectionHeader, KatSpinner } from "@amzn/katal-react";
import { convertToSentenceCase, getFromLocalStorageAndSetTeam, isEmpty, isNewUser, setIfMounted, setNewUserFalse } from "src/utils/common_utils";
import { getAddonStatusBadgeType } from "src/view/notebooks/datastudio_utils";
import { CreateNewButton } from "src/view/style/table_styles";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import { isAdminWindow } from "src/utils/url_utils";
import DataStudioOnboardingResourceMap from "src/view/notebooks/datastudio/commons/data_studio_onboarding_resource_map";
import { selectedTeamSelector } from "src/control/selectors/commons/team_selectors";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";
import { useHistory } from "react-router";
import { OnboardingDetails } from "src/view/notebooks/datastudio/components/onboarding_details";
import { OnboardingForm } from "src/view/notebooks/datastudio/components/onboarding_form";
import { StrongSpan } from "src/view/style/common_styles";
import { isValidCreateOnboardingRequest } from 'src/view/validation/notebook/data_studio_validations';
import { PermissionGroupType, TeamType } from 'src/model/commons/commons_models';
import { listPermissionGroups } from 'src/control/actions/commons/permission_groups_actions';
import { fetchAllTeams, fetchTeam } from "src/control/actions/commons/team_actions";

interface DataStudioOnboardingProps {
    operationType: OperationType
}

export default function DataStudioOnboarding(props: DataStudioOnboardingProps) {
    const dispatch = useDispatch();
    const history = useHistory()
    const params: any = useParams();
    const isAdminView = isAdminWindow();
    const showOnboardingHelp = isNewUser();

    const [onboarding, setOnboarding] = useState(dataStudioInitialState.onboarding.value)
    const [showCreateLoader, setShowCreateLoader] = useState(false)
    const [timer, setTimer] = useState(TEN_MINUTE_POLLING_TIME_INTERVAL)
    const [team, setTeam] = useState<TeamType>({
        teamId: "",
        teamName: "",
        requestedBy: "",
        bindleId: "",
        isAdmin: false,
        description: "",
        cti: "",
        ldapGroup: "",
    })

    const _isMounted = useRef(true);

    let onboardingState = useSelector(dataStudioOnboardingSelector);
    let selectedTeam = useSelector(selectedTeamSelector)
    let username = useSelector(usernameSelector)

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        props.operationType !== OperationType.CREATE && dispatch(getDataStudioOnboarding(params.onboardingId))
        // !isAdminView && dispatch(fetchTeam(params.teamId))
    }, [params]);

    useEffect(() => {
        !isEmpty(username) && dispatch(listPermissionGroups(username, PermissionGroupType.LDAP))
    }, [username]);

    useEffect(() => {
        if (props.operationType !== OperationType.CREATE) {
            setOnboarding({
                ...onboardingState.value,
                teamIdentifier: selectedTeam?.teamName
            })
        }
    }, [selectedTeam]);

    useEffect(() => {
        props.operationType !== OperationType.CREATE && setOnboarding(onboardingState.value)
    }, [onboardingState.value]);

    useEffect(() => {
        if (props.operationType === OperationType.VIEW && onboardingState.value.status === DataStudioOnboardingStatus.CREATE_IN_PROGRESS) {
            const interval = setInterval(() => {
                setIfMounted(_isMounted.current, setTimer, timer - 1)
                timer === 0 &&
                dispatch(getDataStudioOnboarding(params.onboardingId)) &&
                setIfMounted(_isMounted.current, setTimer, TEN_MINUTE_POLLING_TIME_INTERVAL)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [timer, onboardingState]);

    useEffect(() => {
        if (props.operationType === OperationType.CREATE && onboarding.region) {
            dispatch(getDataStudioOnboardingAccount(onboarding.region))
        }
    }, [onboarding.region]);

    useEffect(() => {
        if (props.operationType === OperationType.CREATE) {
            setOnboarding({
                ...onboarding,
                accountId: onboardingState.value.accountId || "",
            });
        }
    }, [onboardingState.value.accountId]);

    useEffect(() => {
        if (props.operationType === OperationType.CREATE && onboardingState.value.onboardingId) {
            setOnboarding({
                ...onboarding,
                onboardingId: onboardingState.value.onboardingId,
            });
        }
    }, [onboardingState.value.onboardingId]);

    const getCardTitle = () => {
        return props.operationType === OperationType.CREATE ? <>Data Studio Onboarding</> : <>
            Data Studio Onboarding : <StrongSpan>{onboarding.name}</StrongSpan> <KatBadge
            label={onboardingState.value.status}
            type={getAddonStatusBadgeType(onboardingState.value.status)}/>
        </>
    }

    const handleSubmit = async (event: any) => {
        try {
            if (isValidCreateOnboardingRequest(onboarding)) {
                setIfMounted(_isMounted.current, setShowCreateLoader, true)
                setNewUserFalse();
                let onboardingId = await dispatch(createDataStudioTeamAndOnboarding({
                    ...onboarding,
                    createdBy: username
                }))
                setIfMounted(_isMounted.current, setShowCreateLoader, false)

                // change the team if a new team was created
                dispatch(fetchAllTeams(username, null, null));
                window.localStorage.setItem('SELECTED_TEAM', onboarding.teamIdentifier);
                getFromLocalStorageAndSetTeam(dispatch);

                history.push(window.location.pathname.replace("/create", `/${onboardingId}/view`))
            }
        } catch (err) {
            dispatch(showSnackBar("Create data studio onboarding failed"))
        }
    }

    const viewOnboarding = (<>
        <CreateNewButton
            disabled={false}
            onClick={() => {
                history.push(window.location.pathname.replace('/view', `/addons`))
            }}
        > Show Addons
        </CreateNewButton>
        <OnboardingDetails onboarding={onboarding}/>
        <KatSectionHeader header="Environment Resources" expandable>
            <div style={{width: '800px'}}>
                <DataStudioOnboardingResourceMap dataStudioOnboarding={onboardingState.value}/>
            </div>
        </KatSectionHeader>
    </>);

    const newUserGuideBox = (
        showOnboardingHelp &&
        <div style={{marginBottom: '20px'}}>
            <KatAlert
                header="Onboard to Spektr Data Studio"
                description="Your team does not have any Data Studio Environments. Fill in the below form to onboard your team"
                variant="info"
                onDismiss={() => setNewUserFalse()}
            ></KatAlert>
        </div>
    );

    const createOnboarding = (<>
        <OnboardingForm onboarding={onboarding} operationType={props.operationType} onChangeHandler={setOnboarding}/>
    </>);

    /**
     TODO : Add onChange and Display error component when create will be implemented : Spektr Milestone 3
     */
    return (
        onboardingState.status === Status.ERROR ?
            (
                params.onboardingId ? <KatFlashbar
                    variant="danger"
                    header={"Error"}
                    description={`Failed to load onboarding for onboardingId : ${params.onboardingId}`}
                /> : <KatFlashbar
                    variant="danger"
                    header={"Error"}
                    description={`Create Onboarding failed with the following error: ${onboardingState.error}`}
                    />
            ) :
            (
                (props.operationType !== OperationType.CREATE && !selectedTeam) || (props.operationType !== OperationType.CREATE && onboardingState.status === Status.LOADING) ?
                    <KatSpinner/> :
                    <>
                        <BreadCrumb dataStudioOnboardingName={onboardingState.value.name}/>
                        {
                            props.operationType !== OperationType.CREATE &&
                            onboardingState.value.status === DataStudioOnboardingStatus.CREATE_IN_PROGRESS && <KatAlert
                                variant="warning"
                                header={"This will take up to 1 hour"}
                                description={`Refreshing in ${Math.floor(timer / 60)}:${timer % 60}`}
                                persistent={true}
                            />
                        }
                        {props.operationType === OperationType.CREATE && newUserGuideBox}
                        <KatCard>
                            <span slot={"subtitle"}>{getCardTitle()}</span>
                            {props.operationType === OperationType.CREATE &&
                                <>
                                    {createOnboarding}
                                    <br />
                                    <KatButton
                                        onClick={handleSubmit}
                                        disabled={!isValidCreateOnboardingRequest(onboarding)}
                                        loading={showCreateLoader}
                                    >{`${convertToSentenceCase(OperationType.CREATE)} Onboarding`}</KatButton>
                                </>
                            }
                            {props.operationType === OperationType.VIEW && viewOnboarding}
                        </KatCard>
                    </>
            )
    )

}
