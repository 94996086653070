import {KatTable, KatTableBody, KatTableCell, KatTableHead, KatTableRow} from "@amzn/katal-react";
import React from "react";
import {DataStudioOnboarding} from "src/model/notebooks/data_studio_models";

interface DataStudioResourceMapProps {
    dataStudioOnboarding: DataStudioOnboarding;
}

export default function DataStudioOnboardingResourceMap(props: DataStudioResourceMapProps) {
    return (
        <KatTable>
            <KatTableHead>
                <KatTableRow>
                    <KatTableCell>Key</KatTableCell>
                    <KatTableCell>Value</KatTableCell>
                </KatTableRow>
            </KatTableHead>
            <KatTableBody>
                {
                    Object.keys(props.dataStudioOnboarding.resources as any).map((key) => {
                        return (<KatTableRow key={key}>
                            <KatTableCell>{key}</KatTableCell>
                            <KatTableCell>{(props.dataStudioOnboarding.resources as any)[key]}</KatTableCell>
                        </KatTableRow>)
                    })
                }
            </KatTableBody>
        </KatTable>
    );
}