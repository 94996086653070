import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {
    BreadCrumbBar,
    BreadCrumbGroup,
    BreadCrumbItemPadding,
    BreadCrumbItemStyle,
    BreadCrumbSeparator
} from "src/view/style/bread_crumb_styles";
import {GreenSpinner} from "src/view/style/header_styles";
import {FLOWS, getFlowFromURL, removeTrailingSlashes} from "src/utils/url_utils";
import {
    CodeRepositoriesCrumbs,
    ConfigPanelCrumbs,
    DatasetCrumbs,
    FeatureGenerationCrumbs,
    JobTemplateCrumbs,
    ModelTrainingCrumbs,
    DataStudioAdminCrumbs,
    NotebooksCrumb,
    RunwaysCrumbs,
    TeamCrumbs, DataStudioCrumbs
} from "./breadcrumb_items";
import {isEmpty, setIfMounted} from "src/utils/common_utils";

export interface Crumb {
    crumbLabel: string,
    crumbPath: string
}

export interface BreadCrumbProps {
    namespaceName?: string,
    runwayName?: string,
    jobName?: string,
    teamName?: string,
    dataStudioOnboardingName?: string,
    dataStudioManagedEndpointName?: string,
    dataStudioVpcEndpointName?: string,
}

export default function BreadCrumb(props: BreadCrumbProps) {
    let currentPath = removeTrailingSlashes(useLocation().pathname);
    let matchedParams = useParams();
    let route = useRouteMatch();
    const [crumbs, setCrumbs] = useState<Crumb[]>([]);

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        let flow = getFlowFromURL(currentPath);
        switch (flow) {
            case FLOWS.DATA_STUDIO:
                let dataStudioCrumbs = DataStudioCrumbs(props, matchedParams, route.path) as Crumb[];
                !isEmpty(dataStudioCrumbs) && setIfMounted(_isMounted.current, setCrumbs, dataStudioCrumbs);
                return;
            case FLOWS.TEAM:
                let teamCrumbs = TeamCrumbs(props, matchedParams, route.path) as Crumb[];
                !isEmpty(teamCrumbs) && setIfMounted(_isMounted.current, setCrumbs, teamCrumbs);
                return;
            case FLOWS.CONFIG_PANEL:
                let configPanelCrumbs = ConfigPanelCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(configPanelCrumbs) && setIfMounted(_isMounted.current, setCrumbs, configPanelCrumbs);
                return;
            case FLOWS.FEATURE_GENERATION:
                let featureGenerationCrumbs = FeatureGenerationCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(featureGenerationCrumbs) && setIfMounted(_isMounted.current, setCrumbs, featureGenerationCrumbs);
                return;
            case FLOWS.MODEL_TRAINING:
                let modelTrainingCrumbs = ModelTrainingCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(modelTrainingCrumbs) && setIfMounted(_isMounted.current, setCrumbs, modelTrainingCrumbs);
                return;
            case FLOWS.JOB_TEMPLATE:
                let jobTemplateCrumbs = JobTemplateCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(jobTemplateCrumbs) && setIfMounted(_isMounted.current, setCrumbs, jobTemplateCrumbs);
                return;
            case FLOWS.NOTEBOOKS:
                let notebooksCrumbs = NotebooksCrumb(props, matchedParams, route.path) as Crumb[];
                !isEmpty(notebooksCrumbs) && setIfMounted(_isMounted.current, setCrumbs, notebooksCrumbs);
                return;
            case FLOWS.RUNWAYS:
                let runwaysCrumbs = RunwaysCrumbs(props, matchedParams, route.path) as Crumb[];
                !isEmpty(runwaysCrumbs) && setIfMounted(_isMounted.current, setCrumbs, runwaysCrumbs);
                return;
            case FLOWS.DATASETS:
                let datasetCrumbs = DatasetCrumbs(props, route.path) as Crumb[];
                !isEmpty(datasetCrumbs) && setIfMounted(_isMounted.current, setCrumbs, datasetCrumbs);
                return;
            case FLOWS.CODE_REPOSITORIES:
                let codeRepoCrumbs = CodeRepositoriesCrumbs(props, route.path) as Crumb[];
                !isEmpty(codeRepoCrumbs) && setIfMounted(_isMounted.current, setCrumbs, codeRepoCrumbs);
                return;
            case FLOWS.DATA_STUDIO_ADMIN:
                let dataStudioAdminCrumb = DataStudioAdminCrumbs(props, matchedParams,route.path) as Crumb[];
                !isEmpty(dataStudioAdminCrumb) && setIfMounted(_isMounted.current, setCrumbs, dataStudioAdminCrumb);
                return;
            default:
                setCrumbs([])
        }
    }, [currentPath, matchedParams])

    if (crumbs && crumbs.length <= 0) {
        return null;
    }

    const showCrumb = (crumb: Crumb, key: number, totalCrumbs: number) => {
        const isFinalCrumb = (key + 1) === totalCrumbs;

        if (isFinalCrumb) {
            return (<kat-breadcrumb-item
                        label={crumb.crumbLabel}
                        href={crumb.crumbPath}
                        key={key}
                        style={BreadCrumbItemStyle}
                    />);
        } else {
            const breadCrumbLink = (
                <Link to={crumb.crumbPath} style={BreadCrumbItemStyle}>
                    {crumb.crumbLabel}
                </Link>
            );
            const arrow = (<BreadCrumbSeparator name="chevron-right" size="tiny"></BreadCrumbSeparator>);

            return (<div key={key} style={{'display': 'flex', 'alignItems': 'center'}}>
                {breadCrumbLink}
                {arrow}
            </div>);
        }
    }

    const showCrumbs = () => {
        return (
            <BreadCrumbGroup>
                <BreadCrumbItemPadding/>
                { crumbs.map((crumb: Crumb, key: number) => showCrumb(crumb, key, crumbs.length)) }
            </BreadCrumbGroup>
        )
    }

    return (
        <BreadCrumbBar>
            {(crumbs && crumbs.length > 0) ?
                showCrumbs() :
                <GreenSpinner variant={"default"} size={"small"}/>
            }
        </BreadCrumbBar>
    )
}