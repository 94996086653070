import styled from "styled-components";
import {KatAlert, KatButton, KatModal} from "@amzn/katal-react";
import { ViewIconTable } from "./icons";


export const CouldNotLoadAlert = styled(KatAlert)`
    margin: auto !important;
    left: 0;
    right: 0;
    max-width: 100vw;
    padding: 0 1vw;
    width: 100%;
    position: fixed;
`

export const PopUp = styled(KatModal)`
`

export const BigPopUp = styled(KatModal)`
  --max-width: 100%;
  --max-height: 100%;
  --height: 90vh;
  --width: 80vw;
`

export const JobPopUp = styled(PopUp)`
    max-width: 600px;
`

export const UpdateJobPopUp = styled(PopUp)`
    max-width: 600px;
`

export const JobOptionsPopUp = styled(JobPopUp)`
`

export const Alert = styled(KatAlert)<{ show: any }>`
    background: #000;
    color: #fff;
    display: flex;
    padding: 0.5rem;
    top: 90vh;
    right: ${({show}) => (show ? '1vw' : '-100%')};
    position: fixed;
    transition: 350ms;
    z-index: 1200;
`;

export const LinkButton = styled(KatButton)`
    border: 2px solid black;
`;

export const TabStyle = {marginTop: "30px", marginLeft: "50px", marginRight: "50px"}

export const ClickablePaddedSpan = styled.span`
    padding: 5px;
    &:hover {
      cursor: pointer;
    }
`

export const StyledKatButton = styled(KatButton)`
  margin-left: 6px;
  margin-top: 30px;
`

export const StrongSpan = styled.span`
  font-weight: bold;
`

export const BigStrongSpan = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
`

export const IconButtonStyle = {
  margin: "5px",
  width: "20px",
  color: "#002e36"
};

export const ClickableImage = styled.img`
  cursor: pointer;
  boxShadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #aaa;
`;