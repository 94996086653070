import {
    KatAlert,
    KatBadge,
    KatButton,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow
} from "@amzn/katal-react";
import ReactTooltip from 'react-tooltip';
import React, {useEffect, useRef, useState} from "react";
import {ErrorContainer, ErrorHeading} from "src/view/style/modelTraining/errors"
import {useDispatch, useSelector} from "react-redux";
import {AddonIcon, InfoIcon, LaunchIcon, ViewIconTable} from "src/view/style/icons";
import {CreateNewButton, FilterInput, PaddedSpan, TableHeader, TableHeaderDiv} from "src/view/style/table_styles";
import {getDataStudioOnboarding, listDataStudioOnboardings} from "src/control/actions/notebooks/data_studio_actions";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {getParamFromQueryString, isAdminWindow, setParamToQueryString} from "src/utils/url_utils";
import {
    DataStudioOnboarding,
    DataStudioOnboardingStatus,
    DataStudioResourceMap
} from "src/model/notebooks/data_studio_models";
import {useHistory, useParams} from "react-router";
import {ClickablePaddedSpan, IconButtonStyle, PopUp} from "src/view/style/common_styles";
import {
    dataStudioOnboardingSelector,
    dataStudioOnboardingsListSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import {Status} from "src/constant";
import DataStudioOnboardingResourceMap from "src/view/notebooks/datastudio/commons/data_studio_onboarding_resource_map";
import { selectedTeamSelector } from "src/control/selectors/commons/team_selectors";


export default function ListDataStudioOnBoardings() {
    const dispatch = useDispatch();
    const history = useHistory();
    // do not remove useParams, this is needed for filtering to work
    useParams();
    const isAdminView = isAdminWindow();

    const [isInfoPopUpVisible, setIsInfoPopUpVisible] = useState(false);
    const [popUpOnboardingId, setPopUpOnboardingId] = useState("");
    const [dataStudioOnboardingPopUpVisibility, setDataStudioOnboardingPopUpVisibility] = useState(false);
    const [filter, setFilter] = useState("");

    let popUpDataStudioOnboarding = useSelector(dataStudioOnboardingSelector);
    let dataStudioOnboardings = useSelector(dataStudioOnboardingsListSelector);
    let filterFromURL = getParamFromQueryString(history.location.search, "filter");
    let selectedTeam = useSelector(selectedTeamSelector);
    let selectedTeamRef = useRef(selectedTeam);

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        if (isAdminView) {
            console.log(`loading all onboardings for admin`);
            dispatch(listDataStudioOnboardings());
        } else if (selectedTeam !== null) {
            console.log(`loading onboardings for team: ${selectedTeam.teamName}`);
            dispatch(listDataStudioOnboardings(undefined, selectedTeam.teamName));
        }

        return () => {
            _isMounted.current = false;
        }
    }, []);


    useEffect(() => {
        // admin view always can see all environments
        if (!isAdminView && selectedTeam !== selectedTeamRef.current) {
            console.log(`loading onboardings for team: ${selectedTeam.teamName}`);
            dispatch(listDataStudioOnboardings(undefined, selectedTeam.teamName));
        }
    }, [selectedTeam])

    useEffect(() => {
        popUpOnboardingId && dispatch(getDataStudioOnboarding(popUpOnboardingId))
    }, [popUpOnboardingId]);

    useEffect(() => {
        if (!isEmpty(filterFromURL)) {
            setIfMounted(_isMounted.current, setFilter, filterFromURL)
        } else {
            setIfMounted(_isMounted.current, setFilter, "")
        }
    }, [filterFromURL])

    const handleFilterChange = (filterString: any) => {
        if (isEmpty(filterString)) {
            setParamToQueryString("filter", null);
            return;
        }
        setParamToQueryString("filter", filterString);
    }

    const getDataStudioStatusBadgeType = (dataStudioStatus: DataStudioOnboardingStatus | "") => {
        switch (dataStudioStatus) {
            case DataStudioOnboardingStatus.ACTIVE :
                return "success";
            default:
                return "tag";
        }
    }

    const handleDataStudioLaunch = (dataStudioResources: DataStudioResourceMap) => {
        window.open(dataStudioResources.studioUrl, "_blank")
    }

    const handleRedirection = (path: string) => {
        history.push(`${window.location.pathname}/${path}`)
    }

    const getResourceMapInfoPopUp = () => {
        return (
            <PopUp
                visible={isInfoPopUpVisible}
                onOpen={() => {
                    setIfMounted(_isMounted.current, setIsInfoPopUpVisible, true);
                }}
                onClose={() => {
                    setIfMounted(_isMounted.current, setIsInfoPopUpVisible, false);
                }}
            >
                <span slot={"title"}>DataStudio Resources</span>
                {
                    popUpDataStudioOnboarding.status == Status.LOADING ? <KatSpinner/> :
                        popUpDataStudioOnboarding.status == Status.ERROR ?
                            <ErrorContainer><ErrorHeading>{popUpDataStudioOnboarding.error}</ErrorHeading></ErrorContainer> :
                            <DataStudioOnboardingResourceMap dataStudioOnboarding={popUpDataStudioOnboarding.value}/>
                }
                <div slot="footer" className="kat-group-horizontal">
                    <KatButton
                        label="Cancel"
                        size="base"
                        variant="primary"
                        onClick={() => {
                            setIfMounted(_isMounted.current, setIsInfoPopUpVisible, false);
                        }}
                    />
                </div>
            </PopUp>
        )
    }

    const getRow = (dataStudioOnboarding: DataStudioOnboarding) => {
        return (
            <KatTableRow key={dataStudioOnboarding.onboardingId}>
                <KatTableCell>{dataStudioOnboarding.name}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.accountId}</KatTableCell>
                {isAdminView && <KatTableCell>{dataStudioOnboarding.teamIdentifier}</KatTableCell>}
                <KatTableCell>{dataStudioOnboarding.region}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.createdBy}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.createdTimestamp}</KatTableCell>
                <KatTableCell><KatBadge label={dataStudioOnboarding.status}
                                        type={getDataStudioStatusBadgeType(dataStudioOnboarding.status)}
                /></KatTableCell>
                <KatTableCell>
                    <ReactTooltip place="bottom" type="dark"/>
                    <ClickablePaddedSpan data-tip="View Onboarding">
                        <ViewIconTable style={IconButtonStyle} onClick={() => {
                            history.push(`${window.location.pathname}/${dataStudioOnboarding.onboardingId}/view`)
                        }}/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="Launch DataStudio">
                        <LaunchIcon style={IconButtonStyle} onClick={() => {
                            handleDataStudioLaunch(dataStudioOnboarding.resources)
                        }}/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="Show Addons">
                        <AddonIcon style={IconButtonStyle} onClick={
                            () => handleRedirection(`${dataStudioOnboarding.onboardingId}/addons`)
                        }/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="View Resources">
                        <InfoIcon style={IconButtonStyle} onClick={
                            () => {
                                setIfMounted(_isMounted.current, setPopUpOnboardingId, dataStudioOnboarding.onboardingId)
                                setIfMounted(_isMounted.current, setIsInfoPopUpVisible, true)
                            }
                        }/>
                    </ClickablePaddedSpan>
                </KatTableCell>
            </KatTableRow>
        )
    }

    const getOnboardingList = () => {
        let displayList = dataStudioOnboardings.value;
        let searchFilters = filter.split(/\s+/i)
        searchFilters
            .filter(value => !isEmpty(value))
            .forEach((value) => {
                displayList = displayList.filter((onboarding: DataStudioOnboarding) => {
                    let searchValues =
                        `${onboarding.accountId} ${onboarding.teamIdentifier}  ${onboarding.name} ${onboarding.region} ${onboarding.status}`.toUpperCase()
                    return searchValues.includes(value.toUpperCase())
                })
            });
        return displayList.sort((onboarding1: DataStudioOnboarding, onboarding2: DataStudioOnboarding) => {
            return (onboarding1.teamIdentifier > onboarding2.teamIdentifier ? 1 :
                (onboarding1.teamIdentifier < onboarding2.teamIdentifier ? -1 :
                    (onboarding1.createdTimestamp > onboarding2.createdTimestamp ? 1 :
                        (onboarding1.createdTimestamp < onboarding2.createdTimestamp ? -1 : 0))));
        });
    }

    const getRows = () => {
        return (<>
            {getOnboardingList().map(getRow)}
        </>)
    }

    const getOnboardings = () => {
        return (
            <>{
                        dataStudioOnboardings.value.length == 0 ?
                            <KatAlert
                                description="Your team does not have any onboardings. Please choose Create Onboarding"
                                header="No Onboardings Found"
                                variant="warning"
                            ></KatAlert>
                            :
                            <KatTable>
                                <KatTableHead>
                                    <KatTableRow>
                                        <KatTableCell>Name</KatTableCell>
                                        <KatTableCell>Account Id</KatTableCell>
                                        {isAdminView && <KatTableCell>Team Identifier</KatTableCell>}
                                        <KatTableCell>Region</KatTableCell>
                                        <KatTableCell>Created By</KatTableCell>
                                        <KatTableCell>Creation Date</KatTableCell>
                                        <KatTableCell>Status</KatTableCell>
                                        <KatTableCell>Actions</KatTableCell>
                                    </KatTableRow>
                                </KatTableHead>
                                <KatTableBody>
                                    {getRows()}
                                </KatTableBody>
                            </KatTable>
            }</>
        )
    }

    const getDataStudioOnboardingPopUp = () => {
        return (
            <>
                <PopUp
                    visible={dataStudioOnboardingPopUpVisibility}
                    onOpen={() => {
                        setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, true);
                    }}
                    onClose={() => {
                        setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, false);
                        history.push("/")
                    }}
                >
                    <span slot={"title"}>Do you want to onboard to Data Studio?</span>
                    <p>Account linked to this namespace is not onboarded to Data Studio. If you want to get started with Data
                        Studio, click on "Create SIM" below.</p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton
                            label="Cancel"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, false);
                                history.push("/")
                            }}
                        />
                        <KatButton
                            label="Create Onboarding"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                history.push(`${window.location.pathname}/create`)
                            }}
                        />
                    </div>
                </PopUp>
            </>
        );
    }

    return (
        <>
            {getDataStudioOnboardingPopUp()}
            {getResourceMapInfoPopUp()}
            {
                (dataStudioOnboardings.status === Status.LOADING && selectedTeam) ?
                <div><KatSpinner/></div> :
                dataStudioOnboardings.status === Status.ERROR ? <ErrorContainer>
                    <ErrorHeading>{dataStudioOnboardings.error}</ErrorHeading>
                </ErrorContainer> :
                <>
                    <TableHeaderDiv>
                        <TableHeader>
                            DataStudio Onboardings
                        </TableHeader>
                        <CreateNewButton
                            disabled={false}
                            label={"Create Onboarding"}
                            onClick={() => {
                                history.push(`${window.location.pathname}/create`)
                            }}
                        />
                    </TableHeaderDiv>
                    { (isAdminView || selectedTeam) ? <>
                        <PaddedSpan>
                            <FilterInput
                                placeholder={"Search here to filter by name, accountId, region, team or status..."}
                                value={filter}
                                onKeyup={(e: any) => {
                                    handleFilterChange(e.target.value)
                                }}
                            />
                        </PaddedSpan>
                        {getOnboardings()}
                    </>
                    : <KatAlert
                        description="Please select your team to see onboardings. If you have not yet onboarded, choose Create Onboarding"
                        header="No Team Selected"
                        variant="warning"
                        ></KatAlert>
                    }
                </>
            }
        </>
    )

}
