import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {createTeam, fetchTeam} from "src/control/actions/commons/team_actions";
import {OperationType, Status} from "src/constant";
import {FormInput} from "src/view/style/modelTraining/form_input_styles";
import {convertToSentenceCase, isEmpty, setIfMounted} from "src/utils/common_utils";
import {DisplayError} from "src/view/commons/display_error";
import {KatButton, KatCard, KatSpinner} from "@amzn/katal-react";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {selectedTeamSelector} from "src/control/selectors/commons/team_selectors";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {PermissionGroup, PermissionGroupType, TeamType} from "src/model/commons/commons_models";
import {FormDropDown} from "src/view/style/runways/form_input_styles";
import {bindlesSelector, ldapsSelector} from "src/control/selectors/commons/permission_group_selector";
import {listPermissionGroups} from "src/control/actions/commons/permission_groups_actions";
import {StyledKatButton} from "src/view/style/common_styles";

interface TeamProps {
    operationType: OperationType
}

export default function Team(props: TeamProps): JSX.Element {
    const dispatch = useDispatch()
    const history = useHistory()
    const params: any = useParams()

    const [searchButtonDisabled, setSearchButtonDisabled] = useState<boolean>(true);
    const [bindleSearchPrefix, setBindleSearchPrefix] = useState<string>("");
    const [showCreateLoader, setShowCreateLoader] = useState<boolean>(false);
    const [team, setTeam] = useState<TeamType>({
        teamId: "",
        teamName: "",
        requestedBy: "",
        bindleId: "",
        isAdmin: false,
        description: "",
        cti: "",
        ldapGroup: "",
    })

    let _isMounted = useRef(false)
    let username = useSelector(usernameSelector)
    let selectedTeam = useSelector(selectedTeamSelector)
    let fetchedLdaps = useSelector(ldapsSelector)
    let fetchedBindles = useSelector(bindlesSelector)

    useEffect(() => {
        _isMounted.current = true
        if (props.operationType === OperationType.VIEW && !isEmpty(params.teamId)) {
            dispatch(fetchTeam(params.teamId))
        }
        return () => {
            _isMounted.current = false
        }
    }, []);

    useEffect(() => {
        props.operationType === OperationType.VIEW && selectedTeam && setIfMounted(_isMounted.current, setTeam, selectedTeam)
        props.operationType === OperationType.CREATE && setIfMounted(_isMounted.current, setTeam, {
            teamId: "",
            teamName: "",
            requestedBy: "",
            bindleId: "",
            isAdmin: false,
            description: "",
            cti: "",
        })
    }, [selectedTeam, props.operationType, _isMounted]);

    useEffect(() => {
        !isEmpty(username) && dispatch(listPermissionGroups(username, PermissionGroupType.LDAP))
    }, [username, _isMounted]);

    const handleSubmit = async () => {
        try {
            if (!isEmpty(team.teamId) &&
                !isEmpty(team.teamName) &&
                !isEmpty(team.bindleId)) {
                setIfMounted(_isMounted.current, setShowCreateLoader, true)
                await dispatch(createTeam({
                    ...team,
                    requestedBy: username
                }))
                window.localStorage.setItem("SELECTED_TEAM", team.teamId);
                history.push("/")
            } else {
                dispatch(showSnackBar("Please enter all required fields"))
            }
        } catch (error: any) {
            dispatch(showSnackBar("Create Team failed"))
        } finally {
            setIfMounted(_isMounted.current, setShowCreateLoader, false)
        }
    }

    const getTeamLdapOptions = () => {
        const options: {}[] = []
        if (!isEmpty(fetchedLdaps.value)) {
            fetchedLdaps.value.map((ldap: PermissionGroup) => {
                options.push({name: ldap.name, value: ldap.id})
            })
        }
        return options;
    }

    const getBindlesDropdownOptions = () => {
        const options: {}[] = []
        if (!isEmpty(fetchedBindles.value)) {
            fetchedBindles.value.map((bindle: PermissionGroup) => {
                options.push({name: bindle.name, value: bindle.id})
            })
        }
        return options;
    }

    const isFieldDisabled = () => {
        return props.operationType === OperationType.VIEW
    }

    return <>
        {
            (props.operationType === OperationType.VIEW && !selectedTeam) || fetchedLdaps.status === Status.LOADING ?
                <KatSpinner/> :
                <>
                    <BreadCrumb teamName={selectedTeam?.teamName}/>
                    <br/>
                    <KatCard>
                        <span slot={"subtitle"}>{convertToSentenceCase(props.operationType.toString()) + " Team"}</span>
                        {
                            props.operationType !== OperationType.VIEW ? <FormDropDown
                                label="Team Ldap*"
                                value={team.teamName}
                                options={getTeamLdapOptions()}
                                placeholder="Choose Team"
                                searchable={true}
                                disabled={isFieldDisabled()}
                                onChange={(event: any) => {
                                    console.log(event)
                                    setTeam({
                                        ...team,
                                        teamName: event.target.value,
                                        teamId: event.target.value
                                    })
                                }}/> : <FormInput
                                label={"Team Name"}
                                value={team.teamName}
                                readonly={true}
                            />

                        }
                        {
                            props.operationType === OperationType.VIEW && <div style={{
                                display: "flex",
                                flexFlow: "row"
                            }}>
                                <FormInput
                                    type="text"
                                    value={team.bindleId}
                                    label={"Bindle Id"}
                                    placeholder={"No Bindle Provided"}
                                    tooltipText="Bindle is be used for managing permission for your expresso resources"
                                    readonly={true}

                                />
                                {
                                    team.bindleId && <a style={{
                                        paddingTop: "30px",
                                        paddingLeft: "10px"
                                    }}
                                                         href={`https://bindles.amazon.com/resource/${team.bindleId}`}
                                                         target={"_blank"}>
                                        link
                                    </a>
                                }
                            </div>
                        }
                        {
                            props.operationType !== OperationType.VIEW && <div style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                                <FormInput
                                    type="text"
                                    value={bindleSearchPrefix}
                                    label={"Search bindle which you are part of for a prefix"}
                                    placeholder={"Search bindle which you are part of for a prefix"}
                                    tooltipText="Bindle you choose will be used for managing permission for your expresso resources"
                                    readonly={isFieldDisabled()}
                                    onInput={(event: any) => {
                                        setBindleSearchPrefix(event.target.value)
                                        setSearchButtonDisabled(isEmpty(event.target.value))
                                    }}
                                />
                                <StyledKatButton label={"Search"} disabled={searchButtonDisabled} onClick={async () => {
                                    setSearchButtonDisabled(true)
                                    await dispatch(listPermissionGroups(username, PermissionGroupType.BINDLE, bindleSearchPrefix))
                                    setSearchButtonDisabled(false)
                                }}/>
                            </div>
                        }
                        {
                            props.operationType !== OperationType.VIEW && fetchedBindles.status === Status.SUCCESS && isEmpty(fetchedBindles.value) &&
                            <DisplayError
                                message={"No bindles found for provided prefix"}
                                operationType={props.operationType}
                            />
                        }
                        <>
                            {
                                props.operationType !== OperationType.VIEW && fetchedBindles.status === Status.SUCCESS && !isEmpty(fetchedBindles.value) && <>
                                    <FormDropDown
                                        label="Bindle Ldap*"
                                        value={team.teamId}
                                        placeholder="Choose Bindle"
                                        tooltipText={"This bindle will be used to manage the permission for the resources within expresso owned by this team"}
                                        options={getBindlesDropdownOptions()}
                                        searchable={true}
                                        disabled={isFieldDisabled()}
                                        onChange={(event: any) => setTeam({
                                            ...team,
                                            bindleId: event.target.value
                                        })}
                                    />
                                    {isEmpty(team.bindleId) && <DisplayError
                                        message={"Please choose bindle"}
                                        operationType={props.operationType}
                                    />}
                                </>
                            }
                        </>
                        <FormInput
                            label="Description"
                            type="text"
                            value={team.description}
                            placeholder={
                                props.operationType === OperationType.VIEW && !team.description ?
                                    "No Description Provided" : "Description for the team"
                            }
                            tooltipText="provide description for your team"
                            readonly={props.operationType === OperationType.VIEW}
                            onChange={(event: any) => setTeam({
                                ...team,
                                description: event.target.value
                            })}
                        />
                    </KatCard>
                    {
                        props.operationType === OperationType.CREATE && <KatButton
                            onClick={handleSubmit}
                            loading={showCreateLoader}
                        >{convertToSentenceCase(props.operationType.toString())} Team</KatButton>
                    }
                </>}
    </>;
}