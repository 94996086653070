import React, {useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import config_panel_logo from "../images/config_panel_logo.png"
import runways_logo from "../images/runways_logo.png"
import code_repo_logo from "../images/code_repos_logo.png"
import datasets_logo from "../images/datasets_logo.jpeg"
import job_templates_logo from "../images/job_templates_logo.png"
import flash_logo from "../images/flash_logo.png"
import notebooks_logo from "../images/notebooks.png"
import spektr_datastudio_logo from "../images/spektr_datastudio_logo.png"
import {GreenSpinner} from "../style/header_styles";
import {
    Card,
    CardBody,
    CardImageWrapper,
    CardsRow,
    CardsWrapper,
    CardTitle,
    LearnMoreLink,
    MainWrapper,
    SubWrapper,
    WelcomeBanner
} from "../style/home_styles";
import {FLOWS, isAdminWindow, isAnchor} from "src/utils/url_utils";
import {getFromLocalStorageAndSetTeam, isEmpty, isNewUser} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {DATA_STUDIO_USER_GUIDE, ONBOARD_MY_TEAM_LINK, TRY_IT_OUT_LINK} from "src/external_links";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {OPERATION_NOT_SUPPORTED_MESSAGE } from "src/constant";


{
    /**
     * TODO : Add Learn more link
     * */
}

export default function Home() {
    const history = useHistory();
    const dispatch = useDispatch();
    let selectedTeam = useSelector((state: any) => state.team.team)
    let username = useSelector(usernameSelector);
    const isAdminView = isAdminWindow()
    const redirectToWelcome = isNewUser();

    const _isMounted = useRef(true);

    useEffect(() => {
        // redirect to welcome if this is a new user
        if (redirectToWelcome) {
            history.push(`/welcome`);
        } else {
            // redirect home page to list onboardings
            history.push(`${isAdminView ? '/admin' : ''}/dataStudio/onboardings`);
        }

        _isMounted.current = true;
        getFromLocalStorageAndSetTeam(dispatch, isAdminView)
        return () => {
            _isMounted.current = false;
        }
    }, [])

    const reRouteToNamespace = (event: any, flow: string, domain = "") => {
        if (isEmpty(selectedTeam)) {
            dispatch(showSnackBar("Please select team to continue"));
        } else {
            let path = `/${flow}/${selectedTeam.teamId}/namespaces` + (domain != "" ? `?domain=` + domain : "");
            if (!isAnchor(event) && !isEmpty(selectedTeam)) {
                history.push(path);
            }
        }
    }

    const reRouteToGlobalUtils = (event: any, flow: string) => {
        let path = `/${flow}`
        if (!isAnchor(event)) {
            history.push(path);
        }
    }

    useEffect(() => {
        // to disable the flex property of the WelcomeBanner that it inherits from the KatAlert katal component.
        // the KatAlert uses the .sc-iUuytg class
        if (username) {
            setTimeout(() => {
                let container = document.querySelector<HTMLElement>(".sc-iUuytg")
                    ?.shadowRoot?.querySelector<HTMLElement>(".content")
                if (container) {
                    container.style.flex = "none"
                }
            }, 0)
        }
    }, [username])

    const getWelcomeBanner = () => {

        if (!username) {
            return <GreenSpinner variant={"default"} size={"small"}/>
        }

        return (
            <WelcomeBanner
                header={"Welcome " + username.toUpperCase() + ", What would you like to do today?"}
                description={"Please choose team to continue"}
                persistent
            />
        )

    }

    const getFlowLogo = (flow : string) => {
        switch (flow) {
            case FLOWS.NOTEBOOKS:
                return notebooks_logo;
            case FLOWS.RUNWAYS:
                return runways_logo;
            case FLOWS.CONFIG_PANEL:
                return config_panel_logo;
            case FLOWS.FLASH:
                return flash_logo;
            case FLOWS.DATASETS:
                return datasets_logo;
            case FLOWS.CODE_REPOSITORIES:
                return code_repo_logo;
            case FLOWS.JOB_TEMPLATE:
                return job_templates_logo;
            case FLOWS.DATA_STUDIO:
                return spektr_datastudio_logo;
            default:
                return;
        }
    }

    const getCard = (flow: string, title: string, body: string, linkText: string, onClickCallback: (event: any, flow: string) => void, href_link?: string) => {
        return <Card onClick={(event: any) => {
                onClickCallback(event, flow)
        }}>
            <CardImageWrapper><img className="flow-logo" src={getFlowLogo(flow)}></img></CardImageWrapper>
            <CardTitle>{title}</CardTitle>
            <CardBody>{body}</CardBody>
            {(!isEmpty(linkText) && !isEmpty(href_link)) && <LearnMoreLink href={href_link} target="_blank" rel="noopener noreferrer">{linkText}</LearnMoreLink> }
        </Card>
    }

    const reRouteToNextPage = (event: any, flow: string) => {
        if(!isAdminView) {
            reRouteToNamespace(event, flow);
        } else if(isEmpty(selectedTeam)){
            dispatch(showSnackBar("Please Select Admin Team"));
        } else if(flow === FLOWS.NOTEBOOKS) {
            history.push(`/admin/${selectedTeam.teamId}/${flow}`);
        } else {
            dispatch(showSnackBar(OPERATION_NOT_SUPPORTED_MESSAGE));
        }
    }

    return (
        <MainWrapper>
            {/* <SubWrapper>
                {getWelcomeBanner()}
            </SubWrapper> */}
            <CardsWrapper>
                {/* <span slot="subtitle">Spektr Products</span> */}
                <CardsRow>
                    {
                        getCard(
                            FLOWS.DATA_STUDIO,
                            'Environments',
                            'Access your Data Studio environment',
                            '',
                            () => {
                                console.log(window.location.pathname);
                                console.log(`isAdminView: ${isAdminView}`);
                                console.log(`selectedTeam: ${selectedTeam.teamId}`);
                                history.push(window.location.pathname + `${isAdminView ? '/' : ''}dataStudio/onboardings`)
                            }
                        )
                    }
                    {
                        <Card onClick={() => { window.open(DATA_STUDIO_USER_GUIDE, '_blank') }}>
                            <CardImageWrapper><img className="flow-logo" src={job_templates_logo}></img></CardImageWrapper>
                            <CardTitle>Documentation</CardTitle>
                            <CardBody>Learn how to use Spektr Data Studio</CardBody>
                        </Card>
                    }
                    {
                        <Card onClick={() => { window.open(TRY_IT_OUT_LINK, '_blank') }}>
                            <CardImageWrapper><img className="flow-logo" src={flash_logo}></img></CardImageWrapper>
                            <CardTitle>Quick Start</CardTitle>
                            <CardBody>Get Access to our Alpha Environment</CardBody>
                        </Card>
                    }
                    {
                        <Card onClick={() => { window.open(ONBOARD_MY_TEAM_LINK, '_blank') }}>
                            <CardImageWrapper><img className="flow-logo" src={runways_logo}></img></CardImageWrapper>
                            <CardTitle>Onboard my team</CardTitle>
                            <CardBody>Request a private environment for your team</CardBody>
                        </Card>
                    }
                </CardsRow>
            </CardsWrapper>
        </MainWrapper>
    )
}