import styled from "styled-components";
import {KatAlert, KatCard} from "@amzn/katal-react";

export const Heading = styled.h2`
`

export const Body = styled.div`
    text-align: justified;
    display: flex;
    justify-content: justified;
`

export const WelcomeBanner = styled(KatAlert)`
    order: 0;
    vertical-align: center;
    justify-content: center;
    max-width: 100vw;
    width: 100vw;
    padding: 0 1vw;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    position: relative;
`

export const LearnMoreLink = styled.a`
    font-size: 10px;
    padding: 1px;
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SubWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const CardsWrapper = styled(KatCard)`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: max-content;
    border: none !important;
`

export const CardsRow = styled.div`
    display: flex;
    justify-content: center;
    // margin-top: 30px;
    // margin-bottom: 30px;
    max-width: max-content;
    margin: auto;
`

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 300px;
    min-height: 300px;
    border-radius: 20px;
    border: 2px solid #232323;
    box-shadow: 8px 8px 6px #dadada,
           -8px -8px 6px #f6f6f6;
    transition: 0.4s;
    margin-left: 40px;
    margin-right: 40px;
    &:hover { 
        translate: 0 -10px; 
        cursor : pointer;
        box-shadow: 15px 15px 6px #dadada,
               -15px -15px 6px #f6f6f6;
    }
`

export const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin: 15px 0 0 10px;
`
export const CardImageWrapper = styled.div`
    height: 90px;
    width: 100px;
    background-color: #c9c9c9;
    border-radius: 15px;
    border: 1px solid #000;
    box-shadow: inset 8px 8px 10px #c3c3c3,
            inset -8px -8px 10px #cfcfcf;
`

export const CardBody = styled.div`
    text-align: center;
    margin: 13px 0 0 10px;
    color: rgb(31, 31, 31);
    font-size: 15px;
`
