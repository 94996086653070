import React from "react";
import {
    DataStudioManagedEndpoint,
    DataStudioEndpointType,
} from "src/model/notebooks/data_studio_models";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import {OperationType, REQUIRED_FIELDS_ERROR_MESSAGE} from "src/constant";
import {isEmpty, isValidEMRReleaseLabel, isValidIAMRoleArn} from "src/utils/common_utils";
import {isValidJSON} from "src/utils/json_utils";
import JSONEditor from "src/view/commons/json_editor";
import {DisplayError} from "src/view/commons/display_error";
import {VStatusIndicator} from "src/view/style/view_config_styles";

interface ManagedEndpointFormProps {
    managedEndpoint: DataStudioManagedEndpoint,
    operationType: OperationType,
    onChangeHandler: any,
}
export function ManagedEndpointForm(props: ManagedEndpointFormProps) {
    const CONFIGURATION_TAB_SPACES = 4;

    let managedEndpoint = props.managedEndpoint;
    let setManagedEndpoint = props.onChangeHandler;

    const getEndpointTypes = () => {
        return [
            {
                name: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY,
                value: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY
            },
            Object.keys(DataStudioEndpointType).map((key: string) => {
                return {
                    name: key.toString(),
                    value: DataStudioEndpointType[key as keyof typeof DataStudioEndpointType].toString()
                }
            })
        ];
    };

    return (<div style={{marginLeft: '10px'}}>
        <FormInput
            label="Name*"
            type="text"
            required
            value={managedEndpoint.name}
            placeholder="Name"
            tooltipText="Name for your endpoint"
            readonly={props.operationType === OperationType.VIEW}
            onChange={(event: any) => {
                console.log('setting the name')
                setManagedEndpoint({
                ...managedEndpoint,
                name: event.target.value
                });
            }}
        />
        {
            isEmpty(managedEndpoint.name) && <DisplayError
                message={REQUIRED_FIELDS_ERROR_MESSAGE}
                operationType={props.operationType}
            />
        }
        <FormDropDown
            label="Endpoint Type*"
            value={props.operationType !== OperationType.CREATE ? managedEndpoint.managedEndpointType : managedEndpoint.type}
            options={getEndpointTypes()}
            tooltipText="click to choose endpoint type"
            placeholder="Select from endpoint type"
            disabled={props.operationType === OperationType.VIEW}
            onChange={(event: any) => {
                setManagedEndpoint({
                    ...managedEndpoint,
                    type: event.target.value
                })
            }}
        ></FormDropDown>
        { props.operationType === OperationType.VIEW &&
            <FormInput
                label="Endpoint Id"
                type="text"
                required
                value={managedEndpoint.managedEndpointId}
                placeholder="Endpoint Id"
                readonly={true}
            />
        }
        { props.operationType === OperationType.VIEW &&
            <FormInput
                label="Virtual ClusterId*"
                type="text"
                required
                value={managedEndpoint.virtualClusterId}
                placeholder="Virtual Cluster Id"
                tooltipText="Virtual Cluster Id to use for endpoint"
                readonly={true}
            />
        }
        <FormInput
            label="Release Label*"
            required
            type="text"
            value={managedEndpoint.releaseLabel}
            placeholder="EMR Release Label"
            tooltipText="EMR Release Labe"
            readonly={props.operationType === OperationType.VIEW}
            onChange={(event: any) => setManagedEndpoint({
                ...managedEndpoint,
                releaseLabel: event.target.value
            })}
        />
        {
            (isEmpty(managedEndpoint.releaseLabel) || !isValidEMRReleaseLabel(managedEndpoint.releaseLabel)) &&
            <DisplayError
                message="Release label can not be empty or should be correct emr release label as per aws"
                operationType={props.operationType}
            />
        }
        { props.operationType === OperationType.VIEW &&
            <FormInput
                label="Execution Role Arn*"
                required
                type="text"
                value={managedEndpoint.executionRoleArn}
                placeholder="Execution Role Arn"
                tooltipText="Execution Role Arn"
                readonly={true}
            />
        }
        <div style={{height: "450px", width: "800px"}}>
            <span>Cluster Configurations</span>
            <VStatusIndicator
                variant={isValidJSON(managedEndpoint.configurationOverrides) ? "success" : "error"}
                label={""}
            />
            <JSONEditor
                fieldName={"Cluster Configurations"}
                value={
                    props.operationType !== OperationType.CREATE ?
                        JSON.stringify(JSON.parse(managedEndpoint.configurationOverrides), null, CONFIGURATION_TAB_SPACES)
                        : managedEndpoint.configurationOverrides
                }
                onChange={(name: any, value: any) => {
                    setManagedEndpoint({
                        ...managedEndpoint,
                        configurationOverrides: value
                    })
                }}
                readOnly={props.operationType === OperationType.VIEW}
            />
        </div>
    </div>);
}