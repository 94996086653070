import { KatButton, KatCard } from "@amzn/katal-react";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";
import { listDataStudioTemplates } from "src/control/actions/notebooks/data_studio_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { dataStudioTemplatesListSelector } from "src/control/selectors/notebooks/data_studio_selectors";
import { getTargetForDataStudioAddonType } from "src/control/targets";
import { DataStudioAddonType, DataStudioTeamType, DataStudioTemplate, DataStudioTemplateAddonParams, TemplateStatus, TemplateType } from "src/model/notebooks/data_studio_models";
import { convertToSentenceCase, isEmpty } from "src/utils/common_utils";
import { isAdminWindow } from "src/utils/url_utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import { FormDropDown, FormInput } from "src/view/style/modelTraining/form_input_styles";

interface CreateAddonUsingTemplate {
    componentType: DataStudioAddonType,
    createFunction: any,
}

export default function CreateAddonUsingTemplate(props: CreateAddonUsingTemplate) {
    const componentType = props.componentType;
    const componentName = getTargetForDataStudioAddonType(componentType);
    const componentTitle = convertToSentenceCase(componentType.toString().replace(/[_]/g,' '));

    const isAdmin = isAdminWindow();
    const history = useHistory();
    const params: any = useParams();
    const dispatch = useDispatch();

    let initialTemplateState = {
        templateId: "",
        templateName: "",
        templateVersion: "",
        teamType: DataStudioTeamType.LDAP_GROUP,
        teamIdentifier: "",
        templateType: TemplateType.COMPONENT,
        componentType: "",
        composedOf: [],
        configuration: "",
        parameters: [],
        status: TemplateStatus.ACTIVE,
        createdBy: "",
        updatedBy: "",
        createdTimestamp: "",
        updatedTimestamp: "",
    };

    const username = useSelector(usernameSelector)
    const [showCreateLoader, setShowCreateLoader] = useState(false)
    const fetchedTemplates = useSelector(dataStudioTemplatesListSelector);
    const [selectedTemplateName, setSelectedTemplateName] = useState("");
    const [selectedTemplate, setTemplate] = useState<DataStudioTemplate>(initialTemplateState);
    const [templateMap, setTemplateMap] = useState<Map<string, DataStudioTemplate[]>>(new Map);
    const [templateList, setTemplateList] = useState<any[]>([]);
    const [templateVersions, setTemplateVersions] = useState<any[]>([]);
    const [templateParameters, setTemplateParameters] = useState<any>({});

    useEffect(() => {
        dispatch(listDataStudioTemplates(componentType));
    }, []);

    useEffect(() => {
        const fetchedTemplateMap: Map<string,DataStudioTemplate[]> = new Map();
        const options: {}[] = [];

        if (!isEmpty(fetchedTemplates.value)) {
            fetchedTemplates.value.map((template: DataStudioTemplate) => {
                options.push({name: template.templateName, value: template.templateName});
                if (!fetchedTemplateMap.has(template.templateName)) {
                    fetchedTemplateMap.set(template.templateName, []);
                }
                fetchedTemplateMap.get(template.templateName)?.push(template);
            })
        }

        setTemplateMap(fetchedTemplateMap);
        setTemplateList(options);

    }, [fetchedTemplates]);

    useEffect(() => {
        const versions: {}[] = [];

        templateMap.get(selectedTemplateName)?.map((template) => {
            versions.push({name: template.templateVersion, value: template.templateId});
        });

        setTemplateVersions(versions);
        setTemplate(initialTemplateState);
    }, [selectedTemplateName]);


    const setSelectedTemplate = (templateId: string) => {
        let selectedTemplate = fetchedTemplates.value.filter((template: DataStudioTemplate) => template.templateId === templateId);
        if (selectedTemplate.length > 0) {
            setTemplate(selectedTemplate[0]);
        }
    }

    const templatesDropDown = (
        <>
            <FormDropDown
                label="Template"
                options={templateList}
                onChange={(event: any) => {
                    setSelectedTemplateName(event.target.value)
                }}
                searchable={true}
            ></FormDropDown>

            <FormDropDown
                label="Template Version"
                value={selectedTemplate.templateId}
                options={templateVersions}
                onChange={(event: any) => {
                    setSelectedTemplate(event.target.value)
                }}
                searchable={false}
            ></FormDropDown>
        </>
    );

    const isValidTemplateParameters = () => {
        return Object.keys(templateParameters).length === selectedTemplate.parameters.length;
    }

    const handleSubmit = () => {
        const formParameters: DataStudioTemplateAddonParams = {
            templateId: selectedTemplate.templateId,
            templateVersion: selectedTemplate.templateVersion,
            onboardingId: params.onboardingId,
            parameters: templateParameters,
            requestedBy: username,
        };

        try {
            if (isValidTemplateParameters()) {
                dispatch(props.createFunction(componentType, formParameters));
                history.push(
                    `/dataStudio/onboardings/${params.onboardingId}/addons`
                );
            }
        } catch (err) {
            dispatch(showSnackBar("Create addon failed"));
        }
    };

    const generatedForm = () => {
        if (selectedTemplate.templateName === initialTemplateState.templateName ||
            selectedTemplate.templateVersion === initialTemplateState.templateVersion) {
            return (<></>);
        }

        let params = selectedTemplate.parameters.map(param => (
            <FormInput
                label={param}
                type="text"
                key={param}
                onChange={(event: any) => {
                    const params = {...templateParameters};
                    params[param] = event.target.value;
                    setTemplateParameters(params);
                }}
            />
        ));

        return (<>
            <hr />
            {params}
            <br />
            <KatButton
                onClick={handleSubmit}
                disabled={!isValidTemplateParameters()}
                loading={showCreateLoader}
            >Create</KatButton>
        </>);
    }

    return (
        <>
            <BreadCrumb />
            <KatCard>
                <span slot={"subtitle"}>{`Create ${componentTitle}`}</span>
                {isAdmin && <Link to={`/dataStudio/onboardings/${params.onboardingId}/addons/${componentName}/create-detailed`}>Detailed Create Form</Link>}
                {templatesDropDown}
                {generatedForm()}
            </KatCard>
        </>
    );
}
