import React from "react";
import { DataStudioVpcEndpoint, } from "src/model/notebooks/data_studio_models";
import { StrongSpan } from "src/view/style/common_styles";

interface VpcEndpointDetailsProps {
    vpcEndpoint: DataStudioVpcEndpoint,
}

export function VpcEndpointDetails(props: VpcEndpointDetailsProps) {
    return (
        <div style={{fontSize: '1.3em', lineHeight: '1.7em', marginBottom: '20px'}}>
            <div>
                <StrongSpan>Addon Id: </StrongSpan>
                {props.vpcEndpoint.addonId}
            </div>
            <div>
                <StrongSpan>Name: </StrongSpan>
                {props.vpcEndpoint.name}
            </div>
            <div>
                <StrongSpan>VPC Id: </StrongSpan>
                {props.vpcEndpoint.vpcId}
            </div>
            <div>
                <StrongSpan>VPC Endpoint Id: </StrongSpan>
                {props.vpcEndpoint.vpcEndpointId}
            </div>
            <div>
                <StrongSpan>Service Name: </StrongSpan>
                {props.vpcEndpoint.serviceName}
            </div>
            <div>
                <StrongSpan>DNS Entries: </StrongSpan>
                <ul>
                    {props.vpcEndpoint.dnsEntries?.map((entry, index) => (
                        <li key={index}>{entry}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
