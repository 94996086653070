import {Status} from "src/constant";

export enum PermissionGroupType {
    BINDLE  = "BINDLE",
    LDAP = "LDAP"
}

export interface PermissionGroup {
    name: string,
    id: string,
    type: PermissionGroupType,
}

export interface PermissionGroupInitialState {
    bindles : {
        value : PermissionGroup[],
        status: Status,
        error: string,
    },
    ldaps : {
        value : PermissionGroup[],
        status: Status,
        error: string,
    }
}
export const permissionGroupInitialState: PermissionGroupInitialState = {
    bindles: {
        value: [],
        status: Status.LOADING,
        error: ""
    },
    ldaps: {
        value: [],
        status: Status.LOADING,
        error: ""
    }
}

export const snackBarInitialState = {
    message: ""
}

export interface TeamType {
    teamId: string,
    teamName: string,
    requestedBy: string,
    bindleId?: string,
    isAdmin: boolean,
    description?: string,
    cti?: string,
    ldapGroup: string,
}

export const teamInitialState  = {
    allTeams: [],
    team: null
}

export const userInitialState = {
    username: null
}

export const regionInitialState = {
    region: null,
    showRegions: false,
    supportedRegions: [],
    loaded: false
}