import {AUTHENTICATION_ERROR} from "src/control/errors";

export const getApiEndpoint = (hostname: string, releaseLabel: number = 1) => {
    //for local deployments
    if (hostname.includes("0.0.0.0") || hostname.includes("localhost")) {
        hostname = 'alpha.datastudio.spektr.advertising.amazon.dev';
    }

    let username = '';
    let endpoint = `api.${hostname}`;
    if (username) {
        endpoint = `${username}.${endpoint}`
    }
    return `https://${endpoint}/v${releaseLabel}/`;
}


export const API_ENDPOINT = getApiEndpoint(window.location.hostname);

export const AuthenticationError = AUTHENTICATION_ERROR;

const _handleResponseCallback = (response: any, callback: any) => {
    const status = response.status;
    if (status === 200) {
        callback(response.data);
    } else if (status >= 300 && status < 400) {
        // redirect usually because, authentication expired
        _redirectToMidway();
    } else {
        // delegate this to standard error handling framework
        console.log('ERROR while calling the backend API ', response);
    }
}

const _handleErrorCallback = (error: any, callback: any) => {
    const status = error.status;
    if (status >= 401 && status < 500) {
        // client error, usually authentication expired
        _redirectToMidway();
    } else {
        // delegate this to standard error handling framework
        console.log('ERROR while calling the backend API ', error);
        callback(error);
    }
}

const _handleResponsePromise = (response: any, resolve: any, reject: any) => {
    const status = response.status;
    if (status === 200) {
        resolve(response.data);
    } else if (status >= 300 && status < 400) {
        // redirect usually because, authentication expired
        _redirectToMidway();
        reject(AuthenticationError);

    } else {
        // delegate this to standard error handling framework
        console.log('ERROR while calling the backend API ', response);
        reject(response);
    }
}

const _handleErrorPromise = (error: any, reject: any) => {
    const status = error.status;
    if (status >= 401 && status < 500) {
        // client error, usually authentication expired
        _redirectToMidway();
        reject(AuthenticationError);
    } else {
        // delegate this to standard error handling framework
        console.log('ERROR while calling the backend API ', error);
        reject(error);
    }

}

const _redirectToMidway = function () {
    const REDIRECT_URI = window.location.href
    const CLIENT_ID = `${window.location.protocol}//${window.location.host}`
    const AUTH_URL = `https://midway-auth.amazon.com/SSO/redirect?redirect_uri=${encodeURI(REDIRECT_URI)}&client_id=${encodeURI(CLIENT_ID)}&scope=openid&response_type=id_token&nonce=123&sentry_handler_version=MidwayNginxModule-1.3-1`
    window.location.replace(AUTH_URL);
}

export const axiosDelete = (target: any, params?: any, releaseLabel?: number) => {
    const axios = require('axios').default;
    return axios.delete(getApiEndpoint(window.location.hostname, releaseLabel) + target, {withCredentials: true, params:params});
}

export const axiosGet = (target: any, params?: any, releaseLabel?: number) => {
    const axios = require('axios').default;
    return axios.get(getApiEndpoint(window.location.hostname, releaseLabel) + target, {withCredentials: true, params:params});
}

export const axiosPost = (target: any, data: any, releaseLabel?: number) => {
    const axios = require('axios').default;
    return axios.post(getApiEndpoint(window.location.hostname, releaseLabel) + target, JSON.stringify(data), {
        withCredentials: true, headers:{'Content-Type': ' application/x-amz-json-1.1'}
    });
}

export const axiosPut = (target: any, data: any, releaseLabel?: number) => {
    const axios = require('axios').default;
    return axios.put(getApiEndpoint(window.location.hostname, releaseLabel) + target, JSON.stringify(data), {
        withCredentials: true, headers:{'Content-Type': ' application/x-amz-json-1.1'}
    });
}

export const apiGetCallback = function (target: string, callbackSuccess: any, callbackFailure: any, params?: any) {
    axiosGet(target, params)
        .then((response: any) => _handleResponseCallback(response, callbackSuccess))
        .catch((error: any) => _handleErrorCallback(error, callbackFailure));
}

export const apiPostCallback = function (target: string, data: any, callbackSuccess: any, callbackFailure: any) {
    axiosPost(target, data)
        .then((response: any) => _handleResponseCallback(response, callbackSuccess))
        .catch((error: any) => _handleErrorCallback(error, callbackFailure));
}

export const apiPutCallback = function (target: string, data: any, callbackSuccess: any, callbackFailure: any) {
    axiosPut(target, data)
        .then((response: any) => _handleResponseCallback(response, callbackSuccess))
        .catch((error: any) => _handleErrorCallback(error, callbackFailure));
}

export const apiGetPromise = (target: any, params?: any, releaseLabel?: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response: any = await axiosGet(target, params, releaseLabel);
            _handleResponsePromise(response, resolve, reject);
        } catch (error) {
            _handleErrorPromise(error, reject);
        }
    })
}

export const apiDeletePromise = (target: any, params?: any, releaseLabel?: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response: any = await axiosDelete(target, params, releaseLabel);
            _handleResponsePromise(response, resolve, reject);
        } catch (error) {
            _handleErrorPromise(error, reject);
        }
    })
}

export const apiPostPromise = (target: any, data: any, releaseLabel?: number) => {
    return new Promise(async(resolve, reject) => {
        try {
            let response: any = await axiosPost(target, data, releaseLabel);
            _handleResponsePromise(response, resolve, reject);
        } catch (error) {
            _handleErrorPromise(error, reject);
        }
    })
}
export const apiPutPromise = function (target: string, data: any, releaseLabel ?: number) {
    return new Promise(async(resolve, reject) => {
        try {
            let response: any = await axiosPut(target, data, releaseLabel);
            _handleResponsePromise(response, resolve, reject);
        } catch (error) {
            _handleErrorPromise(error, reject);
        }
    })
}
