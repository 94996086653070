import React from "react";
import {
    DataStudioOnboarding,
} from "src/model/notebooks/data_studio_models";
import { StrongSpan } from "src/view/style/common_styles";

interface OnboardingDetailsProps{
    onboarding: DataStudioOnboarding;
}

export function OnboardingDetails(props: OnboardingDetailsProps) {
    return (
      <div style={{ fontSize: '1.3em', lineHeight: '1.7em', marginBottom: '20px' }}>
        <div>
            <StrongSpan>Onboarding Id: </StrongSpan>
            {props.onboarding.onboardingId}
        </div>
        <div>
            <StrongSpan>Name: </StrongSpan>
            {props.onboarding.name}
        </div>
        <div>
            <StrongSpan>Team (LDAP): </StrongSpan>
            {props.onboarding.teamIdentifier}
        </div>
        <div>
            <StrongSpan>Account Id: </StrongSpan>
            {props.onboarding.accountId}
        </div>
        <div>
            <StrongSpan>Region: </StrongSpan>
            {props.onboarding.region}
        </div>
        <div>
            <StrongSpan>PM Role: </StrongSpan>
            {props.onboarding.resources?.pmRoleArn}
        </div>
        <div>
            <StrongSpan>Execution Role: </StrongSpan>
            {props.onboarding.resources?.executionRoleArn}
        </div>
      </div>
    );
}