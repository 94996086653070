import {
    LIST_BINDLE_PERMISSION_GROUPS_FAILURE,
    LIST_BINDLE_PERMISSION_GROUPS_SUCCESS,
    LIST_LDAP_PERMISSION_GROUPS_FAILURE,
    LIST_LDAP_PERMISSION_GROUPS_SUCCESS,
    REQUEST_LIST_BINDLES_PERMISSION_GROUPS_START,
    REQUEST_LIST_LDAPS_PERMISSION_GROUPS_START
} from "src/control/actions/action_types";
import {PermissionGroupInitialState, permissionGroupInitialState} from "src/model/commons/commons_models";
import {Status} from "src/constant";

export default function (state: PermissionGroupInitialState = permissionGroupInitialState, action: any) {
    switch (action.type) {
        case REQUEST_LIST_BINDLES_PERMISSION_GROUPS_START :
            return {
                ...state,
                bindles: {
                    ...state.bindles,
                    status: Status.LOADING
                }
            }
        case REQUEST_LIST_LDAPS_PERMISSION_GROUPS_START:
            return {
                ...state,
                ldaps: {
                    ...state.ldaps,
                    status: Status.LOADING,
                }
            }
        case LIST_BINDLE_PERMISSION_GROUPS_SUCCESS:
            return {
                ...state,
                bindles: {
                    ...state.bindles,
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case LIST_LDAP_PERMISSION_GROUPS_SUCCESS:
            return {
                ...state,
                ldaps: {
                    ...state.ldaps,
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case LIST_LDAP_PERMISSION_GROUPS_FAILURE:
            return {
                ...state,
                ldaps: {
                    ...state.ldaps,
                    value: [],
                    status: Status.ERROR,
                    error: action.error
                }
            }
        case LIST_BINDLE_PERMISSION_GROUPS_FAILURE:
            return {
                ...state,
                bindles: {
                    ...state.bindles,
                    value: [],
                    status: Status.ERROR,
                    error: action.error
                }
            }
        default:
            return state
    }
}
