import { DataStudioOnboarding } from 'src/model/notebooks/data_studio_models';
import { isEmpty } from 'src/utils/common_utils';

export const isValidCreateOnboardingRequest = (onboarding: DataStudioOnboarding) => {
    return !isEmpty(onboarding.name) &&
        !isEmpty(onboarding.teamOrg) &&
        !isEmpty(onboarding.region) &&
        !isEmpty(onboarding.teamType) &&
        !isEmpty(onboarding.teamIdentifier) &&
        !isEmpty(onboarding.accountId)
}
