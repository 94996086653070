import {
    CREATE_TEAM_FAILURE,
    CREATE_TEAM_SUCCESS,
    ERROR_CREATING_TEAM,
    FETCH_ALL_TEAMS_FAILURE,
    FETCH_ALL_TEAMS_REQUEST,
    FETCH_ALL_TEAMS_SUCCESS,
    FETCH_TEAM_FAILURE,
    FETCH_TEAM_REQUEST,
    FETCH_TEAM_SUCCESS
} from "../action_types";
import {apiGetCallback, apiPostPromise} from "../../../utils/api_handler";
import {Failure, Request, Success} from "../../../utils/action_utils";
import {ERROR_TEAM_NOT_FOUND, ERROR_WHILE_FETCHING_ALL_TEAMS_INFO} from "src/control/errors";
import { getFetchTeamTarget, getTeamsTarget, getTeamsTargetForUser } from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";
import {TeamType} from "src/model/commons/commons_models";


export const fetchAllTeams = (username: any, callbackOnSuccess: any, callbackOnFailure: any) => (dispatch: any) => {
    dispatch(Request(FETCH_ALL_TEAMS_REQUEST));
    let error = ERROR_WHILE_FETCHING_ALL_TEAMS_INFO;
    apiGetCallback(
        getTeamsTargetForUser(username),
        (data: any) => {
            if(isEmpty(data) || isEmpty(data.teamsList)) {
                dispatch(Failure(FETCH_ALL_TEAMS_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            dispatch(Success(FETCH_ALL_TEAMS_SUCCESS, data.teamsList));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(FETCH_ALL_TEAMS_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        }
    );
}


export const fetchTeam = (teamId: string, callbackOnSuccess?: any, callbackOnFailure?: any) => (dispatch: any) => {
    dispatch(Request(FETCH_TEAM_REQUEST));
    apiGetCallback(
        getFetchTeamTarget(teamId),
        (data: any) => {
            if (isEmpty(data)) {
                let error = ERROR_TEAM_NOT_FOUND;
                dispatch(Failure(FETCH_TEAM_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            dispatch(Success(FETCH_TEAM_SUCCESS, data));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(FETCH_TEAM_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        }
    );
}

export const createTeam = (team: TeamType) => (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
       try {
           let data: any = await apiPostPromise(getTeamsTarget(), team)
           if(data && !isEmpty(data.teamId)) {
               dispatch(Success(CREATE_TEAM_SUCCESS, data))
               resolve();
           } else {
               dispatch(Failure(CREATE_TEAM_FAILURE, ERROR_CREATING_TEAM));
               reject(ERROR_CREATING_TEAM);
           }
       } catch (error: any) {
           dispatch(Failure(CREATE_TEAM_FAILURE, error));
           reject(error);
       }
       return;
    });
}
