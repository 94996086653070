import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { KatBadge, KatCard, KatFlashbar, KatSpinner } from "@amzn/katal-react";
import React, { useEffect, useRef, useState } from "react";
import { dataStudioInitialState } from "src/model/notebooks/data_studio_models";
import { getDataStudioOnboarding, getDataStudioVpcEndpoint } from "src/control/actions/notebooks/data_studio_actions";
import { convertToSentenceCase } from "src/utils/common_utils";
import { OperationType, Status } from "src/constant";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import {
    dataStudioOnboardingSelector,
    dataStudioVpcEndpointSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import { useHistory } from "react-router";
import { getAddonStatusBadgeType } from "src/view/notebooks/datastudio_utils";
import { PaddedSpan } from "src/view/style/table_styles";
import { isAdminWindow } from "src/utils/url_utils";
import { namespaceSelector } from "src/control/selectors/modelTraining/model_training_selectors";
import { StrongSpan } from "src/view/style/common_styles";
import { VpcEndpointDetails } from 'src/view/notebooks/datastudio/components/vpc_endpoint_details';

interface VpcEndpointProps {
    operationType: OperationType
}

export default function DataStudioVpcEndpoint(props: VpcEndpointProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();
    const isAdminView = isAdminWindow()

    const [vpcEndpoint, setVpcEndpoint] = useState(dataStudioInitialState.vpcEndpoint.value);
    const [showCreateLoader, setShowCreateLoader] = useState(false);

    const _isMounted = useRef(true);

    const CONFIGURATION_TAB_SPACES = 4;

    let username = useSelector(usernameSelector);
    let vpcEndpointState = useSelector(dataStudioVpcEndpointSelector);
    let onboarding = useSelector(dataStudioOnboardingSelector);
    let fetchedNamespace = useSelector(namespaceSelector);

    useEffect(() => {
        _isMounted.current = true;
        console.log('Calling get data studio onboarding')
        dispatch(getDataStudioOnboarding(params.onboardingId))
        return () => {
            _isMounted.current = false;
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.operationType === OperationType.VIEW || props.operationType === OperationType.CLONE) {
            dispatch(getDataStudioVpcEndpoint(params.addonId));
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.operationType === OperationType.VIEW) {
            setVpcEndpoint(vpcEndpointState.value);
        } else if (props.operationType === OperationType.CLONE) {
            setVpcEndpoint({
                ...vpcEndpointState.value,
                name: `${vpcEndpointState.value.name}-clone`
            })
        }

    }, [vpcEndpointState]);

    const getCardTitle = () => {
        return props.operationType === OperationType.VIEW ? <>
            VPC Endpoint : <StrongSpan>{vpcEndpoint.name}</StrongSpan> <KatBadge
            label={vpcEndpointState.value.status} type={getAddonStatusBadgeType(vpcEndpointState.value.status)}/>
        </> : <>{convertToSentenceCase(props.operationType)} VPC Endpoint</>
    }

    const getErrorFlashBar = (failedEntity: string, entityId: string) => {
        return <KatFlashbar
            variant="danger"
            header={"Error"}
            description={`Error occurred while loading ${failedEntity} for id: ${entityId}`}
        />
    }

    return (
        <>
            {
                (props.operationType !== OperationType.CREATE && vpcEndpointState.status === Status.ERROR) ?
                    getErrorFlashBar("addon", params.addonId) :
                    onboarding.status === Status.ERROR ?
                        getErrorFlashBar("onboarding", params.onboardingId) :
                        ((props.operationType !== OperationType.CREATE && vpcEndpointState.status === Status.LOADING) ||
                        onboarding.status === Status.LOADING ?
                            <KatSpinner/> :
                            <>
                                <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                            dataStudioOnboardingName={onboarding.value.name}
                                            dataStudioVpcEndpointName={vpcEndpointState.value.name}/>
                                <KatCard>
                                    <span slot={"subtitle"}>{getCardTitle()}</span>
                                    {(props.operationType === OperationType.VIEW || props.operationType === OperationType.CLONE) &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between"
                                        }}>
                                          <PaddedSpan>
                                              {props.operationType === OperationType.VIEW &&
                                                  <VpcEndpointDetails vpcEndpoint={vpcEndpoint}/>}
                                              {props.operationType === OperationType.CLONE &&
                                                  <a href={window.location.pathname.replace("clone", "view")}
                                                     target="_blank">id: {vpcEndpoint.addonId}</a>
                                              }
                                          </PaddedSpan>
                                        </div>
                                    }
                                </KatCard>
                            </>)
            }
        </>
    );
}
