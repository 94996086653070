import React from "react"
import {Route, Switch} from "react-router-dom";
import Header from "./commons/header";
import {Provider} from "react-redux";
import store from "../model/store";
import history from "../history";
import NotFound from "./commons/not_found";
import {BodyDiv, MainDiv} from "./style/header_styles"
import Home from "src/view/commons/home";
import Welcome from "src/view/notebooks/datastudio/welcome";
import {SnackBar} from "src/view/commons/snack_bar";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {ConnectedRouter} from "connected-react-router";
import ListDataStudioAddons from "src/view/notebooks/datastudio/list_data_studio_addons";
import ListDataStudioOnBoardings from "src/view/notebooks/datastudio/list_data_studio_onboardings";
import DataStudioManagedEndpoint from "src/view/notebooks/datastudio/data_studio_managed_endpoint";
import DataStudioOnboarding from "src/view/notebooks/datastudio/data_studio_onboarding";
import CreateAddonUsingTemplate from "src/view/notebooks/datastudio/create_addon_using_template";
import {OperationType} from "src/constant";
import Team from "src/view/commons/team/team";
import DataStudioVpcEndpoint from 'src/view/notebooks/datastudio/data_studio_vpc_endpoint';
import { DataStudioAddonType } from "src/model/notebooks/data_studio_models";
import { createAddonViaTemplate } from "src/control/actions/notebooks/data_studio_actions";

export const App = () => {
    //TODO: Change Router Logic With Routes.ts
    return (
        <Provider store={store}>
            <MainDiv style={{maxWidth: "1500px", margin: "auto"}}>
                <ConnectedRouter history={history}>
                    <Switch>
                        {/* Home Page */}
                        <Route path={"/"} exact>
                            <Header/>
                            <BodyDiv>
                                <Home/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/welcome"} exact>
                            <Header/>
                            <BodyDiv>
                                <Welcome/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/team/create"} exact>
                            <Header/>
                            <BodyDiv>
                                <Team operationType={OperationType.CREATE}/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/team/:teamId/view"} exact>
                            <Header/>
                            <BodyDiv>
                                <Team operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        {/*Admin Home Page */}
                        <Route path={"/admin"} exact>
                            <Header/>
                            <BodyDiv>
                                <Home/>
                            </BodyDiv>
                        </Route>

                        <Route path={["/admin/dataStudio/onboardings", "/dataStudio/onboardings"]} exact>
                            <Header/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListDataStudioOnBoardings/>
                            </BodyDiv>
                        </Route>

                        <Route path={["/admin/dataStudio/onboardings/:onboardingId/view", "/dataStudio/onboardings/:onboardingId/view"]} exact>
                            <Header/>
                            <BodyDiv>
                                <DataStudioOnboarding operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        <Route path={["/admin/dataStudio/onboardings/create", "/dataStudio/onboardings/create"]} exact>
                            <Header/>
                            <BodyDiv>
                                <DataStudioOnboarding operationType={OperationType.CREATE}/>
                            </BodyDiv>
                        </Route>

                        <Route path={["/admin/dataStudio/onboardings/:onboardingId/addons", "/dataStudio/onboardings/:onboardingId/addons"]} exact>
                            <Header/>
                            <BodyDiv>
                                <ListDataStudioAddons/>
                            </BodyDiv>
                        </Route>

                        <Route
                            path={["/admin/dataStudio/onboardings/:onboardingId/addons/managed-endpoint/create-detailed", "/dataStudio/onboardings/:onboardingId/addons/managed-endpoint/create-detailed"]} exact>
                            <Header/>
                            <BodyDiv>
                                <DataStudioManagedEndpoint operationType={OperationType.CREATE}/>
                            </BodyDiv>
                        </Route>

                        <Route
                            path={["/admin/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/view", "/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/view"]}
                            exact>
                            <Header/>
                            <BodyDiv>
                                <DataStudioManagedEndpoint operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        <Route
                             path={["/admin/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/clone", "/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/clone"]}
                             exact>
                             <Header/>
                             <BodyDiv>
                                 <DataStudioManagedEndpoint operationType={OperationType.CLONE}/>
                             </BodyDiv>
                        </Route>

                        <Route
                             path={["/admin/dataStudio/onboardings/:onboardingId/addons/managed-endpoint/create", "/dataStudio/onboardings/:onboardingId/addons/managed-endpoint/create"]}
                             exact>
                             <Header/>
                             <BodyDiv>
                                 <CreateAddonUsingTemplate
                                    componentType={DataStudioAddonType.JUPYTER_MANAGED_ENDPOINT}
                                    createFunction={createAddonViaTemplate}></CreateAddonUsingTemplate>
                             </BodyDiv>
                         </Route>

                        <Route
                            path={["/admin/dataStudio/onboardings/:onboardingId/addons/:addonId/vpc-endpoint/view", "/dataStudio/onboardings/:onboardingId/addons/:addonId/vpc-endpoint/view"]}
                            exact>
                            <Header/>
                            <BodyDiv>
                                <DataStudioVpcEndpoint operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        <Route
                            path={["/admin/dataStudio/onboardings/:onboardingId/addons/vpc-endpoint/create", "/dataStudio/onboardings/:onboardingId/addons/vpc-endpoint/create"]}
                            exact>
                            <Header/>
                            <BodyDiv>
                                <CreateAddonUsingTemplate
                                    componentType={DataStudioAddonType.VPC_ENDPOINT}
                                    createFunction={createAddonViaTemplate}></CreateAddonUsingTemplate>
                            </BodyDiv>
                        </Route>

                        {/* Other Pages*/}
                        <Route path={"/notFound"} exact component={NotFound}/>
                        <Route component={NotFound}/>
                    </Switch>
                </ConnectedRouter>
                <SnackBar timeOut={10000}/>
            </MainDiv>
        </Provider>
    );
}

export default App;