import {Status} from "src/constant";

// TODO : Remove list datastudio once migration to api is complete

export enum DataStudioEndpointType {
    JUPYTER_ENTERPRISE_GATEWAY = "JUPYTER_ENTERPRISE_GATEWAY"
}

export enum DataStudioOnboardingStatus {
    CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
    ACTIVE = "ACTIVE",
    FAILED = "FAILED",
    DELETED = "DELETED"
}

export enum DataStudioRegion {
    US_EAST_1 = "US_EAST_1",
    US_EAST_2 = "US_EAST_2",
}

export enum DataStudioTeamType {
    LDAP_GROUP = "LDAP_GROUP"
}

export interface DataStudioResourceMap {
    studioUrl?: string,
    pmRoleArn?: string,
    executionRoleArn?: string,
}

export interface DataStudioOnboarding {
    onboardingId?: string,
    name: string,
    teamOrg: string,
    teamIdentifier: string,
    status: DataStudioOnboardingStatus | "",
    accountId?: string,
    region: DataStudioRegion,
    resources: DataStudioResourceMap,
    createdTimestamp: string,
    updatedTimestamp: string,
    createdBy: string,
    teamType?: DataStudioTeamType,
    fleet?: string
    template?: string,
}

export enum DataStudioAddonStatus {
    CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
    ACTIVE = "ACTIVE",
    FAILED = "FAILED",
    DELETED = "DELETED"
}

export enum DataStudioAddonType {
    JUPYTER_MANAGED_ENDPOINT = "JUPYTER_MANAGED_ENDPOINT",
    VPC_ENDPOINT = "VPC_ENDPOINT",
}

export enum TemplateStatus {
    ACTIVE = "ACTIVE",
    DEPRECATED = "DEPRECATED",
}

export enum TemplateType {
    COMPONENT = "COMPONENT", 
    COMPOSITE = "COMPOSITE",
}

export interface DataStudioAddon {
    addonId: string,
    name: string,
    status: DataStudioAddonStatus,
    type: DataStudioAddonType,
    createdBy: string,
    createdTimestamp: string
}

export interface DataStudioTemplate {
    templateId: string,
    templateName: string,
    templateVersion: string,
    teamType: DataStudioTeamType,
    teamIdentifier: string,
    templateType: TemplateType,
    componentType?: string,
    composedOf?: DataStudioTemplate[],
    configuration?: string,
    parameters: string[],
    status: TemplateStatus,
    createdBy: string,
    updatedBy: string,
    createdTimestamp: string,
    updatedTimestamp: string,
}

export interface DataStudioTemplateAddonParams {
    templateId: string,
    templateVersion: string,
    onboardingId: string,
    parameters: any,
    requestedBy: string,
}

export interface DataStudioManagedEndpoint {
    onboardingId: string,
    name: string,
    virtualClusterId?: string,
    releaseLabel: string,
    executionRoleArn?: string,
    requestedBy?: string,
    createdBy?: string,
    updatedBy?: string,
    createdTimestamp?: string,
    updatedTimestamp?: string,
    type: DataStudioEndpointType | DataStudioAddonType,
    managedEndpointType?: DataStudioEndpointType,
    configurationOverrides: string,
    status?: string,
    addonId?: string
    managedEndpointId?: string;
}

export interface DataStudioVpcEndpoint {
    onboardingId: string,
    name: string,
    requestedBy?: string,
    createdBy?: string,
    updatedBy?: string,
    createdTimestamp?: string,
    updatedTimestamp?: string,
    type: DataStudioEndpointType | DataStudioAddonType,
    status?: string,
    addonId?: string,
    vpcId?: string,
    serviceName?: string,
    vpcEndpointId?: string,
    dnsEntries?: string[],
}

export interface DataStudioInitialState {
    listDataStudios: {
        dataStudios: [any],
        status: Status
    },
    onboarding: {
        value: DataStudioOnboarding,
        status: Status,
        error: string
    },
    managedEndpoint: {
        value: DataStudioManagedEndpoint,
        status: Status,
        error: string
    },
    vpcEndpoint: {
        value: DataStudioVpcEndpoint,
        status: Status,
        error: string
    }
    listOnboardings: {
        value: DataStudioOnboarding[],
        status: Status,
        error: string
    },
    listAddons: {
        value: DataStudioAddon[],
        status: Status,
        error: string
    },
    listTemplates: {
        value: DataStudioTemplate[],
        status: Status,
        error: string
    },
    addon : {
        status: Status,
        error: string
    }
}

export const dataStudioInitialState: DataStudioInitialState = {
    listDataStudios: {
        dataStudios: [{}],
        status: Status.LOADING
    },
    onboarding: {
        value: {
            onboardingId: "",
            name: "",
            teamOrg: "",
            teamType: DataStudioTeamType.LDAP_GROUP,
            teamIdentifier: "",
            status: "",
            accountId: "",
            region: DataStudioRegion.US_EAST_1,
            resources: {},
            createdTimestamp: "",
            updatedTimestamp: "",
            createdBy: ""
        },
        status: Status.LOADING,
        error: ""
    },
    managedEndpoint: {
        value: {
            onboardingId: "",
            name: "",
            virtualClusterId: "",
            releaseLabel: "",
            executionRoleArn: "",
            requestedBy: "",
            type: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY,
            configurationOverrides: "{}"
        },
        status: Status.LOADING,
        error: ""
    },
    vpcEndpoint: {
      value: {
          onboardingId: "",
          name: "",
          type: DataStudioAddonType.VPC_ENDPOINT
      },
      status: Status.LOADING,
      error: ""
    },
    listAddons: {
        value: [],
        status: Status.LOADING,
        error: ""
    },
    listOnboardings: {
        value: [],
        status: Status.LOADING,
        error: ""
    },
    listTemplates: {
        value: [],
        status: Status.LOADING,
        error: ""
    },
    addon: {
        status: Status.LOADING,
        error: ""
    },
}
