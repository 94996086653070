export enum Regions{
    IAD = "IAD",
    DUB = "DUB",
    PDX = "PDX",
    SIN = "SIN"
}

export const LATEST_CODE_VERSION = "Latest";
export const DEFAULT_BRANCH = "mainline";
export const INVALID_LAMBDA_ARN_MESSAGE = "Please provide ARN of Lambda in format arn:aws:lambda:<region>:<aws_account_id>:function:<function_name>"

export enum ModelTrainingStep{
    PRE_TRAIN = "PRE_TRAIN",
    TRAIN = "TRAIN",
    POST_TRAIN = "POST_TRAIN"
}

export enum JobStep{
    PRE_EXECUTION = "PRE_EXECUTION",
    EXECUTION = "EXECUTION",
    POST_EXECUTION = "POST_EXECUTION"
}

export enum Status {
    InService="InService",
    ERROR="ERROR",
    LOADING="LOADING",
    SUCCESS="SUCCESS"
}

export type SetFunction<T> = (value: T) => void;

export const OPERATION_NOT_SUPPORTED_MESSAGE = "Operation Not Supported !!"

export const REQUIRED_FIELDS_ERROR_MESSAGE = "[Required] Please enter fields marked with *"

export const ADMINS_ALLOWLIST = [
    "acchopr",
    "wonelton",
    "neilwong",
    "zshehade",
    "kmarzhp",
    "mohsmoha",
    "armgh",
    "hanyouji",
    "psaxen",
    "dsachan",
    "khkhanna",
    "swatiku"
]

export enum OperationType {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    VIEW = "VIEW",
    DELETE = "DELETE",
    CLONE = "CLONE"
}

export const POLLING_TIME_INTERVAL = 20

export const TEN_MINUTE_POLLING_TIME_INTERVAL = 600

export const SEEN_WELCOME_SCREEN_FLAG = 'SEEN_WELCOME_SCREEN';