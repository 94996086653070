import styled from "styled-components";
import {
    KatButton,
    KatInput,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableFooter,
    KatTableHead,
    KatTableRow
} from "@amzn/katal-react";

export const Table = styled(KatTable)`
`

export const TableHead = styled(KatTableHead)`
`

export const TableBody = styled(KatTableBody)`
`

export const TableHeadRow = styled(KatTableRow)`
`

export const TableRowWithShadow = styled(KatTableRow)`
    transition: box-shadow .3s;
    
    &:hover {
        box-shadow: 2px 5px 10px #000;
        cursor: pointer;
  }
`

export const TableRow = styled(KatTableRow)`
`

export const TableCell = styled(KatTableCell)`
    
`


export const IconColumn = styled(KatTableCell)`
    width: 10%;
`

export const TableHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TableHeader = styled.h2`
    padding: 1vh;
    font-size: 1.4rem !important;
`

export const PaddedSpan = styled.span`
    padding: 10px;
    padding-right: 15px;
`

export const TableFooter = styled(KatTableFooter)`
`

export const FilterInput = styled(KatInput)`
`

export const CreateNewButton = styled(KatButton)<{disabled: boolean}>`
     --background: ${({ disabled }) => (disabled ? "grey" : '#002e36')};
     float: right;
     margin-bottom: 1vh;
     margin-right: 1vw;
 `;

export const PaddedTableFooter = styled(KatTableFooter)`
    height: 5rem !important;
    padding: 12px;
`

export const TableCellItem = styled.div`
 display: flex
`
export const StyledButton = styled(KatButton)<{disabled: boolean}>`
     --background: ${({ disabled }) => (disabled ? "grey" : '#002e36')};
     float: right;
     margin-bottom: 1vh;
     margin-right: 1vw;
 `;