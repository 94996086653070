import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { CreateNewButton } from "src/view/style/table_styles";
import datastudio_environment from "src/view/images/studio_environment.png";
import { BigPopUp, BigStrongSpan, ClickableImage } from "src/view/style/common_styles";
import { setIfMounted, setNewUserFalse } from "src/utils/common_utils";
import { Link } from "react-router-dom";

export default function Welcome() {
    const history = useHistory();
    const [isImagePopUpVisible, setIsImagePopUpVisible] = useState(false);

    let _isMounted = useRef(true);

    const imageModal = (
        <BigPopUp
            visible={isImagePopUpVisible}
            onOpen={() => {
                setIfMounted(_isMounted.current, setIsImagePopUpVisible, true);
            }}
            onClose={() => {
                setIfMounted(_isMounted.current, setIsImagePopUpVisible, false);
            }}
        >
            <img 
                src={datastudio_environment} 
                style={{height:'100%', border: '1px solid #aaa'}} 
            />
        </BigPopUp>
    );


    const infoBox = (<div>
        <div className="info" style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="left" style={{width: '60%'}}>
                <h2 style={{fontSize: '1.8rem'}}>Welcome to Spektr Data Studio!</h2>
                <br />
                <p>
                    Data Studio is a fully managed, scalable, integrated notebook environment that enables scientists and BIEs to focus on their analysis, removing the overhead of managing cloud infrastructure and connecting to multiple internal Amazon Ads data lakes. Data Studio leverages EMR-EKS , enabling customers to analyze PBs of Ads data in minutes from the familiarity of their Jupyter notebook.
                    <br />
                    <br />
                    <BigStrongSpan>Salient Features</BigStrongSpan>
                </p>
                <ul>
                    <li>Up to 60% faster query performance leveraging PySpark-Pandas on EKS</li>
                    <li>Native integrations with ADP Trino, ADSP Redshift and other AdPT compute stacks</li>
                    <li>Bring your own S3/Glue catalog to join over 5000+ tables on Unified Ads Data Catalog</li>
                    <li>Write back to UADC to leverage Spektr's compliance and discovery tools</li>
                    <li>Easy version control with auto-backups to code.amazon.com</li>
                    <li>Bring your own tools and dependencies using a custom docker image for your environment</li>
                    <li>Create upstream data checks, manage SLAs and debug your scheduled notebook runs</li>
                </ul>
                <br />
                <p><BigStrongSpan style={{fontSize: '1.3rem'}}>Accelerate your Data Journey with Spektr Data Studio 🚀</BigStrongSpan></p>
            </div>
            <div className="right" style={{width: '35%'}}>
                <ClickableImage 
                    src={datastudio_environment} 
                    style={{width:'100%'}} 
                    onClick={() => {
                        setIfMounted(_isMounted.current, setIsImagePopUpVisible, true)
                    }}
                />
            </div>
        </div>
        <div style={{paddingTop: '30px', display: 'flex', justifyContent: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CreateNewButton
                        label={"Onboard My Team"}
                        disabled={false}
                        onClick={() => {
                            history.push(`/dataStudio/onboardings/create`)
                        }}
                    />
                </div>
                <div style={{marginTop: '10px', fontWeight: 'bolder', fontSize: '1.2rem'}}>
                    <Link 
                        to='/datastudio/onboardings' 
                        onClick={() => setNewUserFalse()}
                        >I'm an existing customer, go to my environments
                    </Link>
                </div>
            </div>
        </div>
    </div>);

    return (<>
        {imageModal}
        {infoBox}
    </>);
}