import styled from "styled-components";
import {KatBreadcrumb, KatBreadcrumbItem, KatIcon} from "@amzn/katal-react";

export const BreadCrumbBar = styled.div`
    top: 60px;
    position: fixed;
    height: 40px;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    max-width: 100vw;
    --padding: 0 1vw;
    z-index: 10;
    background: #e2e2e2;
`;

export const BreadCrumbGroup = styled(KatBreadcrumb)`
`

export const BreadCrumbItem = styled(KatBreadcrumbItem)`
`

export const BreadCrumbItemStyle = {
    padding: "10px",
    paddingRight: "0",
    fontSize: "0.8vw",
}

export const BreadCrumbItemPadding = styled.div`
    padding: 10px;
`;

export const BreadCrumbSeparator = styled(KatIcon)`
    --color: #667575;
    --background: none;
    --size: 1.25rem;
    --height: auto;
    --width: auto;
    --padding: 2px 2px 0;
`;