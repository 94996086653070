import React from "react"
import { DisplayError } from "src/view/commons/display_error";
import { DataStudioOnboarding, DataStudioRegion, DataStudioTeamType, } from "src/model/notebooks/data_studio_models";
import { FormDropDown, FormInput } from "src/view/style/modelTraining/form_input_styles";
import { isEmpty } from "src/utils/common_utils";
import { OperationType } from "src/constant";
import { PermissionGroup } from 'src/model/commons/commons_models';
import { ldapsSelector } from 'src/control/selectors/commons/permission_group_selector';
import { useSelector } from "react-redux";

interface OnboardingFormProps {
    onboarding: DataStudioOnboarding;
    operationType: OperationType;
    onChangeHandler: any;
}

export function OnboardingForm(props: OnboardingFormProps) {
    let onboarding = props.onboarding;
    let setOnboarding = props.onChangeHandler;
    let fetchedLdaps = useSelector(ldapsSelector)

    const getRegions = () => {
        return Object.keys(DataStudioRegion).map((key: string) => {
            return {
                name: key.toString(),
                value: DataStudioRegion[key as keyof typeof DataStudioRegion].toString()
            }
        });
    }

    const getTeamTypes = () => {
        return Object.keys(DataStudioTeamType).map((key: string) => {
            return {
                name: key.toString(),
                value: DataStudioTeamType[key as keyof typeof DataStudioTeamType].toString()
            }
        });
    }

    const getTeamLdapOptions = () => {
        const options: {}[] = []
        if (!isEmpty(fetchedLdaps.value)) {
            fetchedLdaps.value.map((ldap: PermissionGroup) => {
                options.push({name: ldap.name, value: ldap.id})
            })
        }
        return options;
    }

    return (
        <>
            <FormDropDown
                label="Region*"
                value={onboarding.region}
                options={getRegions()}
                tooltipText="Click to see supported regions"
                onChange={(event: any) => {
                    setOnboarding({
                        ...onboarding,
                        region: event.target.value
                    })
                }}
            ></FormDropDown>
            <FormInput
                label="Team Org*"
                type="text"
                value={onboarding.teamOrg}
                placeholder="Team organization ex: DSP, SQ, SP, SB"
                onChange={(event: any) => {
                    setOnboarding({
                        ...onboarding,
                        teamOrg: event.target.value
                    })
                }}
            />
            {
                (isEmpty(onboarding.teamOrg)) && <DisplayError
                    message="[Required] Please enter your team's organization"
                    operationType={props.operationType}
                />
            }
            <FormDropDown
                label="Team Type*"
                value={onboarding.teamType}
                options={getTeamTypes()}
                hidden={true}
                tooltipText="Click to see supported team types"
                onChange={(event: any) => {
                    setOnboarding({
                        ...onboarding,
                        teamType: event.target.value
                    })
                }}
            ></FormDropDown>
            <FormDropDown
                label="Team*"
                value={onboarding.teamIdentifier}
                options={getTeamLdapOptions()}
                onChange={(event: any) => {
                    setOnboarding({
                        ...onboarding,
                        teamIdentifier: event.target.value,
                        name: `Spektr Data Studio ${event.target.value}`
                    })
                }}
                searchable={true}
            ></FormDropDown>
            {
                (isEmpty(onboarding.teamIdentifier)) && <DisplayError
                    message="[Required] Please select your team"
                    operationType={props.operationType}
                />
            }
            <FormInput
                label="AccountId"
                type="number"
                value={onboarding.accountId}
                tooltipText="This is a Spektr managed account"
                readonly
                disabled
            />
            {
                (isEmpty(onboarding.accountId)) && <DisplayError
                    message="Unable to find a Spektr managed account in this region. Please raise a ticket to Spektr team."
                    operationType={props.operationType}
                />
            }
            <FormInput
                label="Name*"
                type="text"
                value={onboarding.name}
                placeholder="Name"
                // onChange={(event: any) => {
                //     setOnboarding({
                //         ...onboarding,
                //         name: event.target.value
                //     })
                // }}
                readonly
                disabled
            />
            {
                (isEmpty(onboarding.name)) && <DisplayError
                    message="[Required] Please enter an onboarding name"
                    operationType={props.operationType}
                />
            }
        </>
    );
}