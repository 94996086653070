import React from "react";
import {
    DataStudioManagedEndpoint,
} from "src/model/notebooks/data_studio_models";
import { StrongSpan } from "src/view/style/common_styles";

interface ManagedEndpointDetailsProps {
    managedEndpoint: DataStudioManagedEndpoint,
}

export function ManagedEndpointDetails(props: ManagedEndpointDetailsProps) {
    return (
      <div style={{ fontSize: '1.3em', lineHeight: '1.7em', marginBottom: '20px' }}>
        <div>
            <StrongSpan>Addon Id: </StrongSpan>
            {props.managedEndpoint.addonId}
        </div>
        <div>
            <StrongSpan>Name: </StrongSpan>
            {props.managedEndpoint.name}
        </div>
        <div>
            <StrongSpan>Endpoint Id: </StrongSpan>
            {props.managedEndpoint.managedEndpointId}
        </div>
        <div>
            <StrongSpan>Release Label: </StrongSpan>
            {props.managedEndpoint.releaseLabel}
        </div>
        <div>
            <StrongSpan>Container Image: </StrongSpan>
            {parseImage(props.managedEndpoint)}
        </div>
      </div>
    );
}

function parseImage(managedEndpoint: DataStudioManagedEndpoint) {
    try {
        const configs = JSON.parse(managedEndpoint.configurationOverrides);
        const configProperties: any[] = configs.applicationConfiguration;
        const jupyterKernelOverrides = configProperties.find(o => o.classification === 'jupyter-kernel-overrides');
        const sparkPythonKubernetesConfigs = jupyterKernelOverrides.configurations.find((o:any) => o.classification === 'spark-python-kubernetes');
        return sparkPythonKubernetesConfigs?.properties?.['container-image'];
    }
    catch (e) {
        console.log('unable to retrieve the docker image from the endpoint application config');
        console.log(e);
    }
    return '';
}