import React, {useEffect, useRef, useState} from 'react';
import {
    DropDownDiv,
    DropDownWrapper,
    HeaderDiv,
    HeaderDropDown,
    HeaderLeftDiv,
    HeaderRightDiv,
    SpinnerDiv,
    WebsiteLogo,
    WelcomeUser,
    WhiteSpinner,
} from "../style/header_styles";
import {KatButton,} from "@amzn/katal-react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllTeams, fetchTeam} from "../../control/actions/commons/team_actions";
import {fetchUsername} from "../../control/actions/commons/user_actions";
import {selectRegion} from "src/control/actions/commons/regions_actions";
import {getParamFromURL, isAdminWindow, redirectToHomePage, redirectToNotFound} from "../../utils/url_utils";
import {redirectToRegion} from "../../utils/region_utils";
import {allTeamsSelector, selectedTeamSelector} from "src/control/selectors/commons/team_selectors";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {getFromLocalStorageAndSetTeam, isEmpty, setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {colorWhite, ViewIconTable} from "src/view/style/icons";
import {
    currentRegionSelector,
    regionLoadedSelector,
    showRegionsSelector,
    supportedRegionsSelector
} from "src/control/selectors/commons/regions_selectors";
import {ADMINS_ALLOWLIST} from "src/constant";
import {PopUp} from "src/view/style/common_styles";
import {useHistory} from "react-router";
import studioWebsiteLogo from 'src/view/images/studio-website-logo.png'
import { Link } from 'react-router-dom';

export function Header() {
    const history = useHistory()
    const shouldShowRegion = useSelector(showRegionsSelector);
    const supportedRegions = useSelector(supportedRegionsSelector);
    const regionLoaded = useSelector(regionLoadedSelector);
    let selectedRegion = useSelector(currentRegionSelector);
    const dispatch = useDispatch();
    const teamId = getParamFromURL("teamId");

    //Used to prevent crashes due to memory leak problem
    let _isMounted = useRef(true);
    const isAdminView = isAdminWindow()

    const allTeams = useSelector(allTeamsSelector);
    const selectedTeam = useSelector(selectedTeamSelector);
    const username = useSelector(usernameSelector);

    const [fetchingUsername, setFetchingUsername] = useState(false);
    const [fetchingAllTeams, setFetchingAllTeams] = useState(false);
    const [fetchingSelectedTeam, setFetchingSelectedTeam] = useState(false);

    let showUnAuthorisedPopUp = isAdminView && !isEmpty(username) && !(ADMINS_ALLOWLIST.includes(username))

    const onSuccessTeamFetch = (data: any) => {
        setIfMounted(_isMounted.current, setFetchingSelectedTeam, false);
    }

    const onFailureTeamFetch = (error: any) => {
        setIfMounted(_isMounted.current, setFetchingSelectedTeam, false);
        dispatch(showSnackBar(error.message))
        redirectToNotFound();
    }

    const onSuccessAllTeamFetch = (data: any) => {
        setIfMounted(_isMounted.current, setFetchingAllTeams, false);
    }

    const onFailureAllTeamFetch = (error: any) => {
        setIfMounted(_isMounted.current, setFetchingAllTeams, false);
        dispatch(showSnackBar(error.message));
        redirectToNotFound();
    }

    const onSuccessUsernameFetch = (data: any) => {
        setIfMounted(_isMounted.current, setFetchingUsername, false);
    }

    const onFailureUsernameFetch = (error: any) => {
        setIfMounted(_isMounted.current, setFetchingUsername, false);
        dispatch(showSnackBar(error.message));
        redirectToNotFound();
    }

    const initialize = () => {
        setIfMounted(_isMounted.current, setFetchingUsername, true);
        setIfMounted(_isMounted.current, setFetchingAllTeams, true);
        dispatch(fetchUsername(onSuccessUsernameFetch, onFailureUsernameFetch));
    }

    useEffect(() => {
        _isMounted.current = true;
    })

    useEffect(() => {
      if (_isMounted.current && !isEmpty(username)) {
        window.location.pathname === "/" && dispatch(fetchAllTeams(username, onSuccessAllTeamFetch, onFailureAllTeamFetch));
      }
    }, [window.location.pathname, username])

    useEffect(() => {
        if (_isMounted.current)
            initialize();
    }, [dispatch]);

    useEffect(() => {
        if (_isMounted.current) {
            // teamId && setIfMounted(_isMounted.current, setFetchingSelectedTeam, true);
            // teamId && dispatch(fetchTeam(teamId, onSuccessTeamFetch, onFailureTeamFetch));
            getFromLocalStorageAndSetTeam(dispatch, isAdminView)
        }
    }, [dispatch, teamId]);

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    })

    useEffect(() => {
        if (_isMounted.current && !isEmpty(username)) {
          dispatch(fetchAllTeams(username, onSuccessAllTeamFetch, onFailureAllTeamFetch));
        }
    }, [username])

    const handleTeamChange = (event: any) => {
        const selectedTeam = event.detail.value;
        if(!isEmpty(selectedTeam)) {
            isAdminView && window.localStorage.setItem("ADMIN_TEAM", selectedTeam);
            window.localStorage.setItem("SELECTED_TEAM", selectedTeam);
        }
        dispatch(fetchTeam(selectedTeam, onSuccessTeamFetch, onFailureTeamFetch));
        redirectToHomePage(isAdminView);
    }

    const handleRegionChange = (event: any) => {
        selectedRegion = event.detail.value;
        const hostname = window.location.hostname;
        const component = window.location.pathname.split('/')[1];   //Eg: runways, notebooks
        redirectToRegion(selectedRegion, hostname, teamId, component);
        dispatch(selectRegion(selectedRegion));
    };

    const getTeams = () => {
        if (isEmpty(allTeams) && fetchingAllTeams) {
            return (
                <SpinnerDiv>
                    <p style={colorWhite}><strong>Team: &nbsp;&nbsp;</strong></p>
                    <WhiteSpinner className={"float-right"} variant="default" size="small"/>
                </SpinnerDiv>
            )
        } else if (isEmpty(allTeams) && !fetchingAllTeams) {
            return (
                <SpinnerDiv>
                    Could not load teams
                </SpinnerDiv>
            )
        }

        let filteredTeams = allTeams;

        isAdminView && (filteredTeams = allTeams.reduce((result: any[], team: any) => {
            if(team.isAdmin == true)
                result.push(team)
            return result
        }, []))

        let options = filteredTeams.map((team: any) => (
            {
                name: team.teamName,
                value: team.teamId
            }));

        return (
            <DropDownWrapper>
                <div style={colorWhite}>
                    <strong>Team: &nbsp;</strong>
                </div>
                <HeaderDropDown
                    searchable={true}
                    options={options}
                    value={selectedTeam ? selectedTeam.teamId : ""}
                    placeholder="Choose Team"
                    onChange={(e: any) => {
                        handleTeamChange(e)
                    }}
                    maxHeight={"20vh"}
                />
            </DropDownWrapper>
        )
    }
    const getRegionDropDown = () => {
        if (!regionLoaded) {
            return (
                <SpinnerDiv>
                    <p> Region: &nbsp;&nbsp; </p>
                    <WhiteSpinner variant="default" size="small"/>
                </SpinnerDiv>
            );
        }
        let options = supportedRegions.map((region: any) => (
            {
                name: region,
                value: region
            }));

        return (
            <DropDownWrapper>
                <div style={colorWhite}>
                    Region: &nbsp;
                </div>
                <HeaderDropDown
                    searchable={true}
                    options={options}
                    placeholder="Choose Region"
                    onChange={(event: any) => {
                        handleRegionChange(event)
                    }}
                    value={selectedRegion}
                    maxHeight={"20vh"}
                />
            </DropDownWrapper>
        )
    }
    const getUserName = () => {
        if (isEmpty(username) && fetchingUsername) {
            return (<WhiteSpinner variant="default" size="small"/>);
        } else if (isEmpty(username) && !fetchingUsername) {
            return (<div>Could not load username</div>);
        }

        let userImage = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + username;
        return (
            <>
                <strong> {username} </strong>
                &nbsp;&nbsp;
                <img className={"header-user-img"} src={userImage} alt={"user-image"}/>
            </>
        )
    }

    const getUnAuthorisedPopUp = () => {
        return (
            <>
                <PopUp
                    visible={showUnAuthorisedPopUp}
                    noCloseIcon={true}
                    onClose={() => {
                        history.push("/notFound")
                    }}
                >
                    <span slot={"title"}>Un-Authorised User</span>
                    <p>{username} is unauthorised for admin access</p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton
                            label="Cancel"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                history.push("/notFound")
                            }}
                        />
                    </div>
                </PopUp>
            </>
        );
    }

    return (
        <>
            {getUnAuthorisedPopUp()}
            <HeaderDiv>
                <HeaderLeftDiv>
                    <Link to={`/${isAdminView ? "admin" : ""}`}>
                        <WebsiteLogo src={studioWebsiteLogo} />
                        <h1 className={"heading"}>
                            &nbsp;&nbsp;Spektr Data Studio&nbsp;
                        </h1>
                        {isAdminView ? <h4 className={"admin"}> admin </h4> : <></> }
                    </Link>
                </HeaderLeftDiv>

                <HeaderRightDiv>
                    <DropDownDiv>
                        {getTeams()}
                    </DropDownDiv>
                    <WelcomeUser>
                        Welcome, {getUserName()}
                    </WelcomeUser>
                </HeaderRightDiv>

            </HeaderDiv>
        </>
    );
}

export default Header;
