import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {KatBadge, KatButton, KatCard, KatFlashbar, KatSectionHeader, KatSpinner} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {DataStudioEndpointType, dataStudioInitialState} from "src/model/notebooks/data_studio_models";
import {
    createDataStudioManagedEndpoint,
    getDataStudioManagedEndpoint,
    getDataStudioOnboarding
} from "src/control/actions/notebooks/data_studio_actions";
import {convertToSentenceCase, isEmpty, isValidEMRReleaseLabel, isValidIAMRoleArn, setIfMounted} from "src/utils/common_utils";
import {OperationType, Status} from "src/constant";
import {isValidJSON} from "src/utils/json_utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {
    dataStudioManagedEndpointSelector,
    dataStudioOnboardingSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import {useHistory} from "react-router";
import {getAddonStatusBadgeType} from "src/view/notebooks/datastudio_utils";
import {PaddedSpan} from "src/view/style/table_styles";
import {isAdminWindow} from "src/utils/url_utils";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {ManagedEndpointDetails} from "src/view/notebooks/datastudio/components/managed_endpoint_details";
import {ManagedEndpointForm} from "src/view/notebooks/datastudio/components/managed_endpoint_form";
import { StrongSpan } from "src/view/style/common_styles";

interface ManagedEndpointProps {
    operationType: OperationType
}

export default function DataStudioManagedEndpoint(props: ManagedEndpointProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();
    const isAdminView = isAdminWindow()

    const [managedEndpoint, setManagedEndpoint] = useState(dataStudioInitialState.managedEndpoint.value);
    const [showCreateLoader, setShowCreateLoader] = useState(false);

    const _isMounted = useRef(true);

    const CONFIGURATION_TAB_SPACES = 4;

    let username = useSelector(usernameSelector);
    let managedEndpointState = useSelector(dataStudioManagedEndpointSelector);
    let onboarding = useSelector(dataStudioOnboardingSelector);
    let fetchedNamespace = useSelector(namespaceSelector);

    useEffect(() => {
        _isMounted.current = true;
        console.log('Calling get data studio onboarding')
        dispatch(getDataStudioOnboarding(params.onboardingId))
        return () => {
            _isMounted.current = false;
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.operationType === OperationType.VIEW || props.operationType === OperationType.CLONE) {
            dispatch(getDataStudioManagedEndpoint(params.addonId));
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.operationType === OperationType.VIEW) {
            setManagedEndpoint(managedEndpointState.value);
        }
        else if (props.operationType === OperationType.CLONE) {
            setManagedEndpoint({
                ...managedEndpointState.value,
                name: `${managedEndpointState.value.name}-clone`
            })
        }

    }, [managedEndpointState]);

    const handleSubmit = async () => {
        if (
            !isEmpty(managedEndpoint.virtualClusterId) &&
            !isEmpty(managedEndpoint.name) &&
            !isEmpty(managedEndpoint.type) &&
            isValidEMRReleaseLabel(managedEndpoint.releaseLabel) &&
            isValidJSON(managedEndpoint.configurationOverrides)
        ) {
            try {
                setIfMounted(_isMounted.current, setShowCreateLoader, true)
                await dispatch(createDataStudioManagedEndpoint({
                    onboardingId: params.onboardingId,
                    name: managedEndpoint.name,
                    releaseLabel: managedEndpoint.releaseLabel,
                    type: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY,
                    configurationOverrides: managedEndpoint.configurationOverrides,
                    requestedBy: username,
                }));
                history.push(
                    `/dataStudio/onboardings/${params.onboardingId}/addons`
                );
            } catch (err: any) {
                dispatch(showSnackBar("Create managed endpoint failed"))
            } finally {
                setIfMounted(_isMounted.current, setShowCreateLoader, false)
            }
        }
    };

    const getCardTitle = () => {
        return props.operationType === OperationType.VIEW ? <>
            Managed Endpoint : <StrongSpan>{managedEndpoint.name}</StrongSpan> <KatBadge 
                label={managedEndpointState.value.status} type={getAddonStatusBadgeType(managedEndpointState.value.status)}/>
        </> : <>{convertToSentenceCase(props.operationType)} Managed Endpoint</>
    }

    const getErrorFlashBar = (failedEntity: string, entityId: string) => {
        return <KatFlashbar
            variant="danger"
            header={"Error"}
            description={`Error occurred while loading ${failedEntity} for id: ${entityId}`}
        />
    }

    const printState = () => {
        console.log({
            onboardingId: params.onboardingId,
            name: managedEndpoint.name,
            releaseLabel: managedEndpoint.releaseLabel,
            type: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY,
            executionRoleArn: managedEndpoint.executionRoleArn,
            configurationOverrides: managedEndpoint.configurationOverrides,
            requestedBy: username,
        });
    }

    const loadOnboarding = () => {
        dispatch(getDataStudioOnboarding(params.onboardingId));
    }

    const cloneButton = (
        <div>
            <div style={{
                float: "left",
                display: "flex",
                flexDirection: "row"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                }}>
                    <KatButton
                        onClick={() => {
                            history.push(window.location.pathname.replace("view", "clone"))
                        }}
                    >Clone Endpoint</KatButton>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {
                (props.operationType !== OperationType.CREATE && managedEndpointState.status === Status.ERROR) ?
                    getErrorFlashBar("addon", params.addonId) :
                    onboarding.status === Status.ERROR ?
                        getErrorFlashBar("onboarding", params.onboardingId) :
                        ((props.operationType !== OperationType.CREATE && managedEndpointState.status === Status.LOADING) ||
                        onboarding.status === Status.LOADING ?
                            <KatSpinner/> :
                            <>
                                <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                            dataStudioOnboardingName={onboarding.value.name}
                                            dataStudioManagedEndpointName={managedEndpointState.value.name}/>
                                <KatCard>
                                    <span slot={"subtitle"}>{getCardTitle()}</span>
                                    { (props.operationType === OperationType.VIEW || props.operationType === OperationType.CLONE) &&
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <PaddedSpan>
                                                { props.operationType === OperationType.VIEW && <ManagedEndpointDetails managedEndpoint={managedEndpoint} /> }
                                                { props.operationType === OperationType.CLONE && 
                                                        <a href={window.location.pathname.replace("clone", "view")}
                                                        target="_blank">id: {managedEndpoint.addonId}</a>
                                                }
                                            </PaddedSpan>
                                            { props.operationType === OperationType.VIEW && cloneButton }
                                        </div>
                                    }
                                    {(props.operationType === OperationType.CREATE || props.operationType === OperationType.CLONE) &&
                                        <ManagedEndpointForm managedEndpoint={managedEndpoint} operationType={props.operationType} onChangeHandler={setManagedEndpoint} />
                                    }
                                    {(isAdminView && props.operationType === OperationType.VIEW) &&
                                        <KatSectionHeader header="Managed Endpoint Details" collapsed expandable>
                                            <ManagedEndpointForm managedEndpoint={managedEndpoint} operationType={props.operationType} onChangeHandler={setManagedEndpoint} />
                                        </KatSectionHeader>
                                    }
                                    {
                                        (props.operationType === OperationType.CREATE || props.operationType === OperationType.CLONE) && 
                                        <KatButton
                                            onClick={handleSubmit}
                                            loading={showCreateLoader}
                                        >Create Endpoint</KatButton>
                                    }
                                </KatCard>
                            </>)
            }
        </>
    );
}
