import {
    CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE,
    CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS,
    CREATE_DATA_STUDIO_ONBOARDING_FAILURE,
    CREATE_DATA_STUDIO_ONBOARDING_SUCCESS,
    DELETE_DATA_STUDIO_ADDON_FAILURE,
    DELETE_DATA_STUDIO_ADDON_SUCCESS,
    GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE,
    GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS,
    GET_DATA_STUDIO_ONBOARDING_ACCOUNT_FAILURE,
    GET_DATA_STUDIO_ONBOARDING_ACCOUNT_SUCCESS,
    GET_DATA_STUDIO_ONBOARDING_FAILURE,
    GET_DATA_STUDIO_ONBOARDING_SUCCESS,
    GET_DATA_STUDIO_VPC_ENDPOINT_FAILURE,
    GET_DATA_STUDIO_VPC_ENDPOINT_SUCCESS,
    LIST_DATA_STUDIO_ADDONS_FAILURE,
    LIST_DATA_STUDIO_ADDONS_SUCCESS,
    LIST_DATA_STUDIO_FAILURE,
    LIST_DATA_STUDIO_ONBOARDING_FAILURE,
    LIST_DATA_STUDIO_ONBOARDING_SUCCESS,
    LIST_DATA_STUDIO_REQUEST,
    LIST_DATA_STUDIO_SUCCESS,
    LIST_DATA_STUDIO_TEMPLATES_FAILURE,
    LIST_DATA_STUDIO_TEMPLATES_SUCCESS,
    START_DATA_STUDIO_ONBOARDING_REQUEST
} from "../../actions/action_types";
import { dataStudioInitialState } from "src/model/notebooks/data_studio_models"
import { Status } from "src/constant";

export default function (state = dataStudioInitialState, action: any) {
    switch (action.type) {
        case START_DATA_STUDIO_ONBOARDING_REQUEST:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    status: Status.LOADING,
                }
            }
        case CREATE_DATA_STUDIO_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: {
                        ...state.onboarding.value,
                        onboardingId: action.payload.id,
                        status: action.payload.status
                    },
                    status: Status.SUCCESS
                }
            }
        case CREATE_DATA_STUDIO_ONBOARDING_FAILURE:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: dataStudioInitialState.onboarding.value,
                    status: Status.ERROR,
                    error: action.error
                }
            }
        case LIST_DATA_STUDIO_REQUEST:
            return {
                ...state,
                listDataStudios: {
                    ...state.listDataStudios,
                    status: Status.LOADING
                }
            }
        case LIST_DATA_STUDIO_SUCCESS:
            return {
                ...state,
                listDataStudios: {
                    ...state.listDataStudios,
                    dataStudios: action.payload,
                    status: Status.SUCCESS
                }
            }
        case LIST_DATA_STUDIO_FAILURE:
            return {
                ...state,
                listDataStudios: {
                    ...state.listDataStudios,
                    status: Status.ERROR
                }
            }
        case LIST_DATA_STUDIO_ONBOARDING_SUCCESS:
            return {
                ...state,
                listOnboardings: {
                    ...state.listOnboardings,
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case LIST_DATA_STUDIO_ONBOARDING_FAILURE:
            return {
                ...state,
                listOnboardings: {
                    ...state.listOnboardings,
                    value: [],
                    status: Status.ERROR,
                    error: action.error
                }
            }
        case LIST_DATA_STUDIO_ADDONS_SUCCESS:
            return {
                ...state,
                listAddons: {
                    ...state.listAddons,
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case LIST_DATA_STUDIO_ADDONS_FAILURE:
            return {
                ...state,
                listAddons: {
                    ...state.listAddons,
                    value: [],
                    status: Status.ERROR,
                    error: action.payload
                }
            }
        case GET_DATA_STUDIO_ONBOARDING_SUCCESS:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: {
                        ...state.onboarding.value,
                        ...action.payload
                    },
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case GET_DATA_STUDIO_ONBOARDING_FAILURE:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: {},
                    status: Status.ERROR,
                    error: action.payload
                }
            }
        case GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS:
            return {
                ...state,
                managedEndpoint: {
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE:
            return {
                ...state,
                managedEndpoint: {
                    value: {},
                    status: Status.ERROR,
                    error: action.payload
                }
            }
        case CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS:
            return {
                ...state,
                managedEndpoint: {
                    ...state.managedEndpoint,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE:
            return {
                ...state,
                managedEndpoint: {
                    ...state.managedEndpoint,
                    status: Status.ERROR,
                    error: action.payload
                }
            }
        case DELETE_DATA_STUDIO_ADDON_SUCCESS:
            return {
                ...state,
                addon: {
                    ...state.addon,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case DELETE_DATA_STUDIO_ADDON_FAILURE:
            return {
                ...state,
                addon: {
                    ...state.addon,
                    status: Status.ERROR,
                    error: action.payload
                }
            }
        case GET_DATA_STUDIO_ONBOARDING_ACCOUNT_SUCCESS:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: {
                        ...state.onboarding.value,
                        accountId: action.payload,
                    },
                    error: ""
                }
            }
        case GET_DATA_STUDIO_ONBOARDING_ACCOUNT_FAILURE:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    value: {
                        ...state.onboarding.value,
                        accountId: ""
                    },
                    error: action.error
                }
            }
        case GET_DATA_STUDIO_VPC_ENDPOINT_SUCCESS:
            return {
                ...state,
                vpcEndpoint: {
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case GET_DATA_STUDIO_VPC_ENDPOINT_FAILURE:
            return {
                ...state,
                vpcEndpoint: {
                    ...state.vpcEndpoint,
                    status: Status.ERROR,
                    error: action.error
                }
            }
        case LIST_DATA_STUDIO_TEMPLATES_SUCCESS:
            return {
                ...state,
                listTemplates: {
                    ...state.listTemplates,
                    value: action.payload,
                    status: Status.SUCCESS,
                    error: ""
                }
            }
        case LIST_DATA_STUDIO_TEMPLATES_FAILURE:
            return {
                ...state,
                listTemplates: {
                    ...state.listTemplates,
                    value: [],
                    status: Status.ERROR,
                    error: action.error
                }
            }
        default:
            return state;
    }
}
