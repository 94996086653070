import styled from "styled-components";
import {
    KatAlert,
    KatButton,
    KatDropdown,
    KatInput,
    KatModal,
    KatStatusindicator,
    KatTable,
    KatTableCell,
    KatTableRow
} from "@amzn/katal-react";

export const VTable = styled(KatTable)`
    margin: 0vh auto !important;
    // max-width: 50vw;
    
`;

export const VTableHeader = styled.h2`
`;

export const VDiv = styled.div`
    margin: 5% 25%;
    display:flex;
    justify-content: center;
`;

export const GenericAlert = styled(KatAlert)`
    left: 0;
    right: 0;
`

export const VKey = styled(KatTableCell)`
    font-weight: 700;
    padding: 10px !important;
    vertical-align: top;
`;

export const VValue = styled(KatTableCell)`
    padding: 10px !important;
`;

export const VJSON = styled.textarea`
    height: 100%;
    width: 100%;
    font-family: courier new;
    font-weight: bold;
`;

export const VEditButton = styled(KatButton)`
    float: right;
    width: 20%;
    margin 0.5em;
`;

export const VDeleteButton = styled(KatButton)`
    float: right;
    --background: grey;
    pointer-events: none;
    width: 20%;
    margin: 0.5em;
`;

export const VDropDown = styled(KatDropdown)`
`;

export const VLoadMore = styled(KatButton)`
    float: right;
    width: 20%;
    margin: 0.5em;
`;

export const VInput = styled(KatInput)`
`

export const PopUp = styled(KatModal)`
    --max-width: 100vw;
`

export const VCancelPopUpButton = styled(KatButton)`
    float: right;
    --background: #c81839;
    margin: 0.5em;
`

export const VSubmitPopUpButton = styled(KatButton)`
    float: right;
    margin: 0.5em;
`

export const VCreatePopUpButton = styled(KatButton)`
    float: right;
    margin: 0.5em;
`

export const VCancelButton = styled(KatButton)`
    float: right;
    --background: #c81839;
    width: 20%;
    margin: 0.5em;
`

export const VSubmitButton = styled(KatButton)`
    float: right;
    width: 20%;
    margin 0.5em;
`

export const VInputHiddenDiv = styled(KatButton)`
    display: none;
`
export const VStatusIndicator = styled(KatStatusindicator)`
`

export const VReview = styled(VSubmitButton)`
`