import {PermissionGroupType} from "src/model/commons/commons_models";
import {apiGetPromise} from "src/utils/api_handler";
import {getPermissionGroupTarget} from "src/control/targets";
import {Failure, Request, Success} from "src/utils/action_utils";
import {
    ERROR_GETTING_BINDLES,
    ERROR_GETTING_LDAPS,
    LIST_BINDLE_PERMISSION_GROUPS_FAILURE,
    LIST_BINDLE_PERMISSION_GROUPS_SUCCESS,
    LIST_LDAP_PERMISSION_GROUPS_FAILURE,
    LIST_LDAP_PERMISSION_GROUPS_SUCCESS,
    REQUEST_LIST_BINDLES_PERMISSION_GROUPS_START,
    REQUEST_LIST_LDAPS_PERMISSION_GROUPS_START
} from "src/control/actions/action_types";

export const listPermissionGroups = (username: string, type: PermissionGroupType, searchPrefix?: string) => (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            dispatch(Request(
                type === PermissionGroupType.LDAP ? REQUEST_LIST_LDAPS_PERMISSION_GROUPS_START :
                    REQUEST_LIST_BINDLES_PERMISSION_GROUPS_START
            ))
            let data: any = await apiGetPromise(getPermissionGroupTarget(), {
                username: username,
                permissionGroupType: type,
                searchPrefix: searchPrefix
            })
            if (data && data.permissionGroups) {
                dispatch(Success(
                    type === PermissionGroupType.BINDLE ?
                        LIST_BINDLE_PERMISSION_GROUPS_SUCCESS : LIST_LDAP_PERMISSION_GROUPS_SUCCESS, data.permissionGroups
                ))
                resolve();
                return;
            } else {
                dispatch(Failure(
                    type === PermissionGroupType.BINDLE ? LIST_BINDLE_PERMISSION_GROUPS_FAILURE : LIST_LDAP_PERMISSION_GROUPS_FAILURE,
                    type === PermissionGroupType.BINDLE ? ERROR_GETTING_BINDLES : ERROR_GETTING_LDAPS,
                ))
                reject(type === PermissionGroupType.BINDLE ? ERROR_GETTING_BINDLES : ERROR_GETTING_LDAPS);
            }
        } catch (err: any) {
            dispatch(Failure(
                type === PermissionGroupType.BINDLE ? LIST_BINDLE_PERMISSION_GROUPS_FAILURE : LIST_LDAP_PERMISSION_GROUPS_FAILURE,
                JSON.stringify(err.response.data.message)
            ))
            reject(err)
        }
    });
}